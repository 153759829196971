import { ProfilePage } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfilePage';
import { getValueByPath, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';

export const PackageProfile = () => {

    return (
        <ProfilePage
            entityType={UserPlatformManagementEntityTypes.Package}
            getProfileTitle={getPackageProfileTitle}
            editProfileButtonText='Edit Package'
        />
    );
}

function getPackageProfileTitle(data: any) {
    const defaultTitle: string = 'Package Profile';
    const package_name = getValueByPath(data, 'name') || '';
    return package_name || defaultTitle;
};