import { ViewdataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';

export function getValuesByPath(source: any, valuePath?: string, noSplitByDot: boolean = false): any[] {
    if (!valuePath) return [];

    if (valuePath === '.') {
        return [source];
    }

    if (noSplitByDot && source.hasOwnProperty(valuePath)) {
         source = source[valuePath];
         return Array.isArray(source) ? source : [source];
    }

    const paths = valuePath.split('.');

    for (let i = 0; i < paths.length; i++) {
        if (!source) {
            return []; // Path does not exist
        }
        if (Array.isArray(source)) {
            return source.reduce((acc, item) => acc.concat(getValuesByPath(item, paths.slice(i).join('.'))), []);
        }
        if (!source.hasOwnProperty(paths[i])) {
            return []; // Path does not exist
        }
        source = source[paths[i]];
    }
    return Array.isArray(source) ? source : [source];
}

export function getValuesByPathDotsIndependent(source: any, valuePath?: string): Array<any> {
    if (!valuePath) return [];

    if (valuePath === '.') {
        return [source];
    }

    const paths = valuePath.split('.');

    function resolvePath(src: any, remainingPaths: string[], visited = new Set()): any[] {
        if (!src) return []; // Source is null or undefined
        if (!remainingPaths.length) return Array.isArray(src) ? src : [src]; // Fully resolved path

        const currentPath = remainingPaths.join('.');
        const currentKey = remainingPaths[0];

        const results: any[] = [];
        const keyIdentifier = JSON.stringify({ src, remainingPaths });

        // Avoid reprocessing the same source key
        if (visited.has(keyIdentifier)) return [];
        visited.add(keyIdentifier);

        // Handle exact match for flattened keys (e.g., "a.b.c" or "a.b")
        if (src.hasOwnProperty(currentPath)) {
            results.push(...resolvePath(src[currentPath], [], visited));
        }

        // Handle partial flattened keys (e.g., "a.b" resolving "c.test")
        for (let i = 1; i <= remainingPaths.length; i++) {
            const partialPath = remainingPaths.slice(0, i).join('.');
            if (src.hasOwnProperty(partialPath)) {
                results.push(...resolvePath(src[partialPath], remainingPaths.slice(i), visited));
            }
        }

        // Handle nested traversal (normal behavior)
        if (src.hasOwnProperty(currentKey)) {
            results.push(...resolvePath(src[currentKey], remainingPaths.slice(1), visited));
        }

        // Handle array traversal
        if (Array.isArray(src)) {
            for (const item of src) {
                results.push(...resolvePath(item, remainingPaths, visited));
            }
        }

        // Remove duplicates from the results
        return Array.from(new Set(results));
    }

    return resolvePath(source, paths);
}

export function getValueByPath(source: any, valuePath?: string, fieldViewData?: ViewdataFieldDefinition, noSplitByDot: boolean = false) {
    if (valuePath === '.') {
        return source;
    }

    let result = getValuesByPath(source, valuePath, noSplitByDot);

    if (result.length === 0) return undefined;
    if (fieldViewData?.valueSelection === 'single') result = [result[0]]; // if explicitly single value needed, do not throw an exception
    if (result.length > 1) {
        throw new Error(
            `Error in getValueByPath function. Multiple values found for path: ${valuePath}. Values found: ${JSON.stringify(
                result,
            )}`,
        );
    }

    return result[0];
}

export function addPathToValue(path: string, value: Array<object> | object) {
    /**
     * Creates a nested object structure based on the provided path, with the provided value as the innermost value.
     *
     * @param {string} path - A string representing the path to the innermost object. Each key should be separated by a '.'.
     * @param {Array<object> | object} value - The value to be placed at the innermost level of the nested object structure.
     * @returns {object} The resulting nested object structure.
     *
     * @example
     * let path = "a.b.c";
     * let value = [{name: "test1"}, {name: "test2"}];
     * let result = addPathToValue(path, value);
     * // result will be:
     * // {
     * //     a: {
     * //         b: {
     * //             c: [{name: "test1"}, {name: "test2"}]
     * //         }
     * //     }
     * // }
     */
    const keys = path.split('.');
    return keys.reverse().reduce((acc, key) => {
        return { [key]: acc };
    }, value);
}

export function mapValues(keysDict: Record<string, string>, dataDict: Record<string, string>): Record<string, string> {
    /**
     * Maps the values from one dictionary to another based on a provided keys dictionary.
     *
     * @param {Record<string, string>} keysDict - A dictionary where the keys represent the keys of the result, and the values represent the keys in the data dictionary.
     * @param {Record<string, string>} dataDict - A dictionary containing the data to be mapped.
     * @returns {Record<string, string>} A new dictionary where the keys are the keys from the keys dictionary and the values are the values from the data dictionary corresponding to the value of the key in the keys dictionary.
     *
     * @example
     * let keysDict = { "newKey1": "oldKey1", "newKey2": "oldKey2" };
     * let dataDict = { "oldKey1": "value1", "oldKey2": "value2" };
     * let result = mapValues(keysDict, dataDict);
     * // result will be:
     * // {
     * //   "newKey1": "value1",
     * //   "newKey2": "value2"
     * // }
     */
    return Object.keys(keysDict).reduce((result: Record<string, string>, key) => {
        let dataKey = keysDict[key];
        return { ...result, [key]: dataDict[dataKey] };
    }, {});
}
