import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { defaultNoResultsSymbol } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { CellRendererParam } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import {
    Categories,
    LinkTargetTypes,
    MarketTrendsDateRanges,
    getValueByPath,
    getValuesByPath,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, Link } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isNil } from 'lodash';
import { ColumnDef } from './types';

function getCompanyDetailsHref({
    cfraId,
    compositeTicker,
    exchangeCode,
}: {
    cfraId?: string;
    compositeTicker?: string;
    exchangeCode?: string;
}) {
    if (!cfraId && !compositeTicker && !exchangeCode) {
        return;
    }

    if (cfraId) {
        return `/etf/cfra-id/${cfraId}`;
    }

    return `/etf/ticker/${compositeTicker}/exchange/${exchangeCode}`;
}

export function getCompanyDetailsLinkRenderer({
    cfraIdPath,
    compositeTickerPath,
    exchangeCodePath,
    cardName,
    categoryLevel,
    dateRange,
    linkPath,
    urlLinkPattern,
    target,
}: {
    cfraIdPath?: string;
    compositeTickerPath?: string;
    exchangeCodePath?: string;
    cardName: string;
    categoryLevel?: Categories;
    dateRange?: MarketTrendsDateRanges;
    linkPath?: string;
    urlLinkPattern?: string;
    target?: string;
}) {
    return (props: ICellRendererParams) => {
        const value = props.valueFormatted || props.value;

        const cfraId = getValueByPath(props.data, cfraIdPath);
        const compositeTicker = getValueByPath(props.data, compositeTickerPath);
        const exchangeCode = getValueByPath(props.data, exchangeCodePath);

        const link = getValueByPath(props.data, linkPath);
        const filledLinkPattern = urlLinkPattern
            ? fillTemplate({
                  templateName: 'urlLinkPattern',
                  template: urlLinkPattern,
                  dataObject: props.data,
              })
            : undefined;

        if (!cfraId && !compositeTicker && !exchangeCode && !filledLinkPattern && !link) {
            return value;
        }

        const handleOnClick = () => {
            globalThis.analytics?.registerAction?.({
                action: `open company details link : ${props.colDef?.headerName}`,
                cardName: cardName,
                dateRange: dateRange,
                ticker: compositeTicker,
                etfName: props.data.composite_name,
                etfAssetClass: props.data.asset_class,
                cfraId: cfraId,
                selectedCategory: categoryLevel,
                etfExchange: exchangeCode,
            });
        };

        let linkUrl: string =
            filledLinkPattern || link || getCompanyDetailsHref({ cfraId, compositeTicker, exchangeCode });

        if (target === LinkTargetTypes.CurrentTab) {
            if (!linkUrl.startsWith('/')) {
                linkUrl = `/${linkUrl}`;
            }

            return (
                <Link
                    component={RouterLink}
                    to={linkUrl}
                    underline='none'
                    className='company-details-url'
                    onClick={handleOnClick}>
                    {value}
                </Link>
            );
        }

        return (
            <a
                onClick={handleOnClick}
                href={linkUrl}
                target={'_blank'}
                rel='noreferrer'
                className='company-details-url'>
                {value}
            </a>
        );
    };
}

function sort(a: any, b: any) {
    if (isNil(a) && isNil(b)) { // null or undefined
        return 0; // No change in order
    }

    if (typeof a != 'string' && typeof b != 'string') {
        throw new Error('getCellRenderer sort function got invalid values.');
    }

    return a?.toLowerCase?.()?.localeCompare?.(b?.toLowerCase());
}

export type CellRendererValueProcessor = (props: {
    resultChild?: string | React.ReactNode;
    component?: string;
    resultValue?: any;
    param?: CellRendererParam;
    noResultsSymbol?: string;
}) => React.ReactNode;

export function getCellRenderer({
    cellRendererParams,
    cardName,
    cellRendererValueProcessor,
    urlLinkPattern,
    noResultsSymbol = defaultNoResultsSymbol,
}: {
    cellRendererParams: Array<CellRendererParam>;
    cardName: string;
    cellRendererValueProcessor?: CellRendererValueProcessor;
    urlLinkPattern?: string;
    noResultsSymbol?: string;
}) {
    return (props: ICellRendererParams) => {
        return (
            <Box className={`${props.colDef?.field?.replaceAll('.', '_')} ${(props.colDef as ColumnDef)?.cellRendererParentClass}`}>
                {cellRendererParams.map((param, index) => {
                    const field = param?.item_data?.root || param?.field || props.colDef?.field;

                    if (!field) {
                        throw new Error(
                            `getCellRenderer received invalid field in ${
                                props.colDef?.headerName
                            } column, for ${JSON.stringify(param)} param`,
                        );
                    }

                    const resultChildren: Array<string | React.ReactNode> = [];
                    const resultValues: Array<any> = getValuesByPath(props.data, field, param?.item_data?.split_by_root_and_field ? true : false);

                    if (resultValues.length === 0) {
                        resultValues.push(noResultsSymbol); // show noResultsSymbol if no values found
                    }

                    const getSortValue = (value: any) =>
                        param?.item_data ? getValueByPath(value, param.item_data.field, undefined, param?.item_data?.split_by_root_and_field ? true : false) : value;

                    if (param.sort === 'asc') {
                        resultValues.sort((a, b) => sort(getSortValue(a), getSortValue(b)));
                    } else {
                        resultValues.sort((a, b) => sort(getSortValue(b), getSortValue(a)));
                    }

                    let componentFilterField: Array<any> = [];
                    if (param?.component_filter_field) {
                        componentFilterField = getValuesByPath(props.data, param.component_filter_field);
                    }

                    if (param?.pass_all_values_to_component) {
                        if (!cellRendererValueProcessor || !param.component) {
                            throw new Error(
                                `getCellRenderer received invalid cellRendererValueProcessor or component in ${
                                    props.colDef?.headerName
                                } column, for ${JSON.stringify(
                                    param,
                                )} param. The cellRendererValueProcessor and param.component are required if param?.pass_all_values_to_component is true`,
                            );
                        }

                        resultChildren.push(
                            cellRendererValueProcessor({
                                component: param.component,
                                resultValue: resultValues,
                                param,
                                noResultsSymbol,
                            }),
                        );
                    } else {
                        resultValues.forEach((resultValue, index) => {
                            let resultChild: string | React.ReactNode = resultValue;

                            if (param.link && resultValue !== noResultsSymbol) {
                                resultChild = getCompanyDetailsLinkRenderer({
                                    cfraIdPath: 'id',
                                    cardName,
                                    urlLinkPattern,
                                    target: param.link_target,
                                })({
                                    value: param?.item_data ? resultValue[param.item_data.field] : resultValue,
                                    data: param?.item_data ? resultValue : props.data,
                                } as ICellRendererParams<any, any, any>);
                                // can cause error in case getCompanyDetailsLinkRenderer return function use another properties
                                // from ICellRendererParams<any, any, any>, but in this case only value and data uses.
                            }

                            if (cellRendererValueProcessor && param.component) {
                                resultChild = cellRendererValueProcessor({
                                    resultChild,
                                    component: param.component,
                                    resultValue:
                                        componentFilterField.length > index ? componentFilterField[index] : resultValue,
                                    param,
                                    noResultsSymbol,
                                });
                            }

                            resultChildren.push(resultChild);
                        });
                    }

                    return (
                        <Box className={field?.replaceAll('.', '_')} key={index}>
                            {React.Children.toArray(resultChildren)}
                        </Box>
                    );
                })}
            </Box>
        );
    };
}
