import { IMetadataFields, ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';

import { applyFieldMetadataToValue } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import _ from 'lodash';
import { UseQueryResult } from 'react-query';

export enum AuditTrailFields {
    UpdateType = 'update_type',
    From = 'from',
    To = 'to',
}

const ActionOperationMap = {
    "CREATE": "Add",
    "UPDATE": "Edit",
    "DELETE": "Remove",
}

const AuditTables = {
    UserGroup: 'user_group',
    User: 'user',
    Account: 'account',
    PackageEntitlement: 'package_entitlement',
}

function getAuditTrailMetadataFields(fields: any[]) {
    const metadataFields: any[] = [];
    _.forIn(AuditTrailFields, (fieldName, key) => {
        const _field = fields.find((field: { [x: string]: any; }) => field?.[fieldName]);
        if (!_field) {
            if (fieldName === AuditTrailFields.UpdateType) {
                metadataFields.push({
                    update_type: {
                        label: 'Update Type',
                        type: 'string'
                    },
                })
            } else if (fieldName === AuditTrailFields.From) {
                metadataFields.push({
                    from: {
                        label: 'From',
                        type: 'string'
                    },
                })
            } else if (fieldName === AuditTrailFields.To) {
                metadataFields.push({
                    to: {
                        label: 'To',
                        type: 'string'
                    },
                })
            }
        }
    })

    return [...fields, ...metadataFields];
}

function compareJson(jsonA: { [x: string]: any }, jsonB: { [x: string]: any }) {
    const diffJsonA: { [key: string]: any } = {};
    const diffJsonB: { [key: string]: any } = {};

    if (Object.is(jsonA, jsonB)) {
        return [diffJsonA, diffJsonB];
    }

    Object.keys(jsonA || {})
        .concat(Object.keys(jsonB || {}))
        .forEach((key) => {
            if (jsonA?.[key] !== jsonB?.[key] && !Object.is(jsonA?.[key], jsonB?.[key])) {
                diffJsonA[key] = jsonA?.[key];
                diffJsonB[key] = jsonB?.[key];
            }
        });
    return [diffJsonA, diffJsonB];
}

//handle all audit trail json records for any entity
export function processAuditTrailRecords(data: UseQueryResult<ScreenerData>) {
    const ignore_fields = ['created_date', 'updated_date', 'first_login', 'last_login'];
    const defaultNoResultsSymbol = 'NA';


    //convert json record data to array of audit objects
    if (data?.data?.results?.data && data?.data?._metadata?.fields) {
        const _data = data?.data?.results?.data || [];
        const dataUpdated = _data.find((item) => item?.update_type || item?.from || item?.to);

        if (!dataUpdated) {
            const auditRows: { op?: string, update_type: string; from: any; to: any }[] = [];
            for (let i = 0; i < _data.length; i++) {

                const [new_record, old_record] = compareJson(_data[i].record, _data[i].old_record) || [];

                // handles audit trail for user group
                if (_data[i].table_name === AuditTables.UserGroup) {
                    const action = _data[i].op as keyof typeof ActionOperationMap;
                    const from = _data[i].old_group?.name || defaultNoResultsSymbol;
                    const to = _data[i].group?.name || defaultNoResultsSymbol;
                    if (from !== to) {
                        auditRows.push({
                            ..._data[i],
                            op: ActionOperationMap[action],
                            from: _data[i].old_group?.name || defaultNoResultsSymbol,
                            to: _data[i].group?.name || defaultNoResultsSymbol,
                            update_type: 'Group',
                        });
                    }
                } else if (_data[i].table_name === AuditTables.PackageEntitlement) {
                    const action = _data[i].op as keyof typeof ActionOperationMap;
                    auditRows.push({
                        ..._data[i],
                        op: ActionOperationMap[action],
                        update_type: 'Entitlement',
                        from: defaultNoResultsSymbol,
                        to: defaultNoResultsSymbol,
                    });
                } else if (new_record && old_record) { // handles audit trail for user, account etc
                    Object.keys(new_record).forEach((key) => {

                        if (ignore_fields.includes(key)) {
                            return;
                        }

                        let from = old_record[key];
                        let to = new_record[key];
                        let update_type = key;

                        const fieldMetadata = data.data._metadata.fields.find((field) => {
                            const fieldKey = Object.keys(field)?.[0]
                            return fieldKey === `record->>${key}`
                        });

                        if (fieldMetadata?.[`record->>${key}`]) {
                            from = applyFieldMetadataToValue({ fieldMetadata: fieldMetadata[`record->>${key}`], rawValue: from });
                            to = applyFieldMetadataToValue({ fieldMetadata: fieldMetadata[`record->>${key}`], rawValue: to });

                            if (fieldMetadata?.[`record->>${key}`].type === 'date') {
                                if (from === 'Invalid date') {
                                    from = old_record[key];
                                }
                                if (to === 'Invalid date') {
                                    to = new_record[key];
                                }
                            }
                            update_type = fieldMetadata[`record->>${key}`].label;
                        }
                        const action = _data[i].op as keyof typeof ActionOperationMap;
                        auditRows.push({
                            ..._data[i],
                            op: ActionOperationMap[action],
                            update_type,
                            from,
                            to
                        });
                    });
                }
            }

            data.data.results = {
                data: auditRows,
                total: auditRows.length,
            };
        }
    }

    //add audit trail fields to viewdata (update_type, from, to)
    if (data?.data?._viewdata?.fields) {
        const viewFieldsUpdated = data.data._viewdata.fields.find((field) => field?.from);
        if (!viewFieldsUpdated) {
            const viewdataFields = [
                ...data.data._viewdata.fields,
                {
                    update_type: {
                        order: 10000,
                        min_width: 210,
                    },
                },
                {
                    from: {
                        order: 10100,
                        min_width: 210,
                    },
                },
                {
                    to: {
                        order: 10200,
                        min_width: 210,
                    },
                },
            ];
            data.data._viewdata.fields = viewdataFields;
        }
    }

    //add audit trail fields to metadata (update_type, from, to)
    if (data?.data?._metadata?.fields) {
        const metadataFieldsUpdated = data.data._metadata.fields.find((field) => field?.from);
        if (!metadataFieldsUpdated) {
            data.data._metadata.fields = getAuditTrailMetadataFields(data.data._metadata.fields) as IMetadataFields[];
        }
    }

    return data;
}