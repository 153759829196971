import { ItemDescription } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFCard';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import Box from '@mui/material/Box';
import { CSSProperties } from 'react';

export function BulletMessage({ message, style }: { message: Array<string>; style: CSSProperties }) {
    return (
        <Box sx={{ overflow: 'auto', ...scrollbarThemeV2 }}>
            <ul style={{ paddingLeft: '20px', ...style }}>
                {message.map((line, index) => (
                    <li key={index}>
                        <ItemDescription>{line}</ItemDescription>
                    </li>
                ))}
            </ul>
        </Box>
    );
}
