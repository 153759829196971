import {
    ControlledAccordions,
    ControlledAccordionsProps,
} from '@cfra-nextgen-frontend/shared/src/components/ControlledAccordions';
import { PermanentDrawerLeft } from '@cfra-nextgen-frontend/shared/src/components/PermanentDrawerLeft';
import * as React from 'react';
import { useMemo } from 'react';

export function AccordionsSidebar({
    accordionsConfig,
    children,
    drawerWidth = 300,
    topGap,
}: {
    accordionsConfig: ControlledAccordionsProps;
    children: React.ReactNode;
    drawerWidth?: number;
    topGap: number;
}) {
    const memorizedChildren = useMemo(() => children, [children]);

    return (
        <PermanentDrawerLeft
            topGap={topGap}
            drawerWidth={drawerWidth}
            drawerContent={
                <ControlledAccordions configId={accordionsConfig.configId} config={accordionsConfig.config} />
            }>
            {memorizedChildren}
        </PermanentDrawerLeft>
    );
}
