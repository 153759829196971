import { ProfilePage } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfilePage';
import {
    getValueByPath,
    UserPlatformManagementEntityTypes,
} from '@cfra-nextgen-frontend/shared/src/utils';

const relatedParams: Array<{ key: string; value: string }> = [
    { key: 'account_name', value: 'account.company_name' },
    { key: 'group_name', value: 'name' }
];

export function ManageGroup() {
    return (
        <ProfilePage
            entityType={UserPlatformManagementEntityTypes.Group}
            getProfileTitle={(data: any, defaultTitle: string = 'Group Profile') =>
                getValueByPath(data, 'name') || defaultTitle
            }
            editProfileButtonText='Edit Group'
            relatedParams={relatedParams}
        />
    );
}