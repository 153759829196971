import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerFormAutocompleteFreesolo } from './ScreenerFormAutocompleteFreesolo';
import { ScreenerFormAutocompleteSearchTable } from './ScreenerFormAutocompleteSearchTable';
import ScreenerFormCheckbox from './ScreenerFormCheckbox';
import { ScreenerFormDatePicker } from './ScreenerFormDatePicker';
import { ScreenerFormDateRangePicker } from './ScreenerFormDateRangePicker';
import ScreenerFormFilePicker from './ScreenerFormFilePicker';
import { ScreenerFormInputSlider } from './ScreenerFormInputSlider';
import { ScreenerFormInputText } from './ScreenerFormInputText';
import ScreenerFormRating from './ScreenerFormRating';
import ScreenerFormStaticText from './ScreenerFormStaticText';
import ScreenerFormStaticTextHidden from './ScreenerFormStaticTextHidden';
import ScreenerFormSwitch from './ScreenerFormSwitch';
import { ScreenerFormVirtualizeAutocomplete } from './ScreenerFormVirtualizeAutocomplete';
import { ScreenerFormVirtualizeAutocompleteOutsideChips } from './ScreenerFormVirtualizeAutocompleteOutsideChips';
import { FilterProps } from './types';
//import { ScreenerFormAutocompleteSingleSelect } from './ScreenerFormAutocompleteSingleSelect';

export const FilterComponentNameToComponent: Partial<Record<Components, React.FC<FilterProps>>> = {
    [Components.Autocomplete]: ScreenerFormVirtualizeAutocomplete,
    [Components.AutoCompleteFreeSolo]: ScreenerFormAutocompleteFreesolo,
    [Components.AutocompleteOutsideChips]: ScreenerFormVirtualizeAutocompleteOutsideChips,
    [Components.AutocompleteSearchTable]: ScreenerFormAutocompleteSearchTable,
    [Components.Checkbox]: ScreenerFormCheckbox,
    [Components.DatePicker]: ScreenerFormDatePicker,
    [Components.DateRangePicker]: ScreenerFormDateRangePicker,
    [Components.Dropdown]: ScreenerFormVirtualizeAutocomplete,
    [Components.FilePicker]: ScreenerFormFilePicker,
    [Components.Hidden]: ScreenerFormStaticTextHidden,
    [Components.Rating]: ScreenerFormRating,
    [Components.Slider]: ScreenerFormInputSlider,
    [Components.StaticText]: ScreenerFormStaticText,
    [Components.Switch]: ScreenerFormSwitch,
    [Components.Text]: ScreenerFormInputText,
   // [Components.AutocompleteSingleSelect]: ScreenerFormAutocompleteSingleSelect,
};
