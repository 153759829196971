import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { controlledAccordionsDefaultBorder, controlledAccordionsDefaultTheme } from './theme';
import { AccordionConfig } from './types';
import { getItemId } from './utils';

export type ControlledAccordionsProps = {
    configId?: string; // for the cases of using a few instances of ControlledAccordions component on the same page
    config: Array<AccordionConfig>;
};

export function ControlledAccordions({ configId, config }: ControlledAccordionsProps) {
    const [expanded, setExpanded] = React.useState<string | false | undefined>(undefined);
    const navigate = useNavigate();
    const location = useLocation();

    const defaultExpandedAccordionIndex = useMemo(
        () => config.findIndex((accordionConfig) => accordionConfig.defaultSelected),
        [config],
    );

    // handle default expended accordion
    useEffect(() => {
        if (expanded === undefined && defaultExpandedAccordionIndex !== -1) {
            setExpanded(getItemId(configId, config[defaultExpandedAccordionIndex].name, defaultExpandedAccordionIndex));
        }
    }, [configId, config, expanded, defaultExpandedAccordionIndex]);

    const handleChange = useCallback(
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        },
        [],
    );

    return (
        <ThemeProvider theme={controlledAccordionsDefaultTheme}>
            {config.map(({ name, onClickCallback, onClickPath, items, icon }, configIndex) => {
                const itemId = getItemId(configId, name, configIndex);

                if (onClickPath) {
                    const oldOnClick = onClickCallback;

                    onClickCallback = () => {
                        oldOnClick?.();
                        navigate(onClickPath);
                    };
                }

                if (!items) {
                    return (
                        <Fragment key={itemId}>
                            <Divider sx={{ borderBottom: controlledAccordionsDefaultBorder }} />
                            <ListItem onClick={onClickCallback} disablePadding>
                                <ListItemButton>
                                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                    <ListItemText primary={name} />
                                </ListItemButton>
                            </ListItem>
                        </Fragment>
                    );
                }

                return (
                    <Accordion
                        key={itemId}
                        expanded={expanded === itemId}
                        onChange={(event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
                            onClickCallback?.();
                            handleChange(itemId)(event, expanded);
                        }}
                        disableGutters>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${itemId}bh-content`}
                            id={`${itemId}bh-header`}>
                            <Typography>{name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {items?.map((item, itemIndex) => (
                                <Fragment key={itemIndex}>
                                    <Divider />
                                    <ListItem
                                        onClick={() => {
                                            item.onClickCallback?.();
                                            if (item.onClickPath && location.pathname !== item.onClickPath) {
                                                navigate(item.onClickPath);
                                            }
                                        }}
                                        disablePadding>
                                        <ListItemButton>
                                            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    </ListItem>
                                </Fragment>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </ThemeProvider>
    );
}
