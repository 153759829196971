import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { SendAlertWrapper } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/SendAlertWrapper';
import { UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export function ManageUsers() {
    return (
        <ScreenerHome
            cardName='Manage Users'
            modals={[
                {
                    props: {
                        title: 'Create User',
                        submitButtonName: 'Create User',
                        entityType: UserPlatformManagementEntityTypes.User,
                        onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                    },
                    ModalWrapper: SendAlertWrapper,
                },
            ]}
            screenerRequestParams={{ path: 'user' }}
            searchPlaceholder='Search for User'
            useSSRMode={false}
        />
    );
}
