import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { Dispatch, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import { cancelButtonText as CancelButtonText, confirmButtonText as ConfirmButtonText } from './utils';

const cancelButtonStyles = {
    '&:hover': {
        color: '#3078B5',
        backgroundColor: '#E5E9EF',
        border: '1px solid #007BB8',
    },
};

export function CancelWithConfirmation({
    confirmModalText,
    isDirty,
    analyticsCardName,
    onCancel,
    externalOpenConfirmationModal,
    setExternalOpenConfirmationModal,
    preventChangingRoute,
}: {
    confirmModalText: string;
    isDirty: boolean;
    analyticsCardName: string;
    onCancel: () => void;
    externalOpenConfirmationModal?: boolean;
    setExternalOpenConfirmationModal?: Dispatch<boolean>;
    preventChangingRoute?: boolean;
}) {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const nextPathnameRef = useRef<string>();

    const navigate = useNavigate();

    useBlocker(
        useCallback(
            ({ nextLocation }: any) => {
                if (preventChangingRoute && isDirty) {
                    nextPathnameRef.current = nextLocation.pathname;
                    setShowConfirm(true);
                    return true;
                }

                return false;
            },
            [isDirty, preventChangingRoute],
        ),
    );

    useEffect(() => {
        if (externalOpenConfirmationModal) {
            setExternalOpenConfirmationModal?.(false);

            if (isDirty) {
                setShowConfirm(true);
            } else {
                onCancel();
            }
        }
    }, [externalOpenConfirmationModal, setExternalOpenConfirmationModal, isDirty, onCancel, setShowConfirm]);

    const handleCancelOperationClick = useCallback(() => {
        if (isDirty) {
            setShowConfirm(true);
        } else {
            onCancel();
        }
        globalThis.analytics?.registerAction?.({
            action: `click on ${CancelButtonText}`,
            cardName: analyticsCardName,
        });
        nextPathnameRef.current = undefined;
    }, [analyticsCardName, isDirty, onCancel, setShowConfirm]);

    const handleCancelClick = useCallback(() => {
        setShowConfirm(false);
        globalThis.analytics?.registerAction?.({
            action: `click on ${CancelButtonText}`,
            cardName: analyticsCardName,
        });
    }, [analyticsCardName, setShowConfirm]);

    const handleConfirmClick = useCallback(() => {
        setShowConfirm(false);
        onCancel();
        globalThis.analytics?.registerAction?.({
            action: `click on ${ConfirmButtonText}`,
            cardName: analyticsCardName,
        });

        if (nextPathnameRef.current) {
            const pathnameCopy = nextPathnameRef.current;
            nextPathnameRef.current = undefined;

            // require setTimeout here to make navigate work
            setTimeout(() => navigate(pathnameCopy), 0);
        }
    }, [analyticsCardName, onCancel, navigate, setShowConfirm]);

    const roundedTextButton = useMemo(() => {
        return (
            <RoundedTextButton
                type='button'
                outlined
                theme={roundedTextButtonThemeV2BorderRadius4}
                buttonText={CancelButtonText}
                sx={cancelButtonStyles}
                onClickCallback={handleCancelOperationClick}
            />
        );
    }, [handleCancelOperationClick]);

    const confirmationModal = useMemo(() => {
        return (
            <ConfirmationModal
                openModal={showConfirm}
                modalText={confirmModalText}
                cancelButtonText={CancelButtonText}
                confirmButtonText={ConfirmButtonText}
                cancelCallback={handleCancelClick}
                confirmCallback={handleConfirmClick}
            />
        );
    }, [confirmModalText, handleCancelClick, handleConfirmClick, showConfirm]);

    return (
        <>
            {roundedTextButton}
            {confirmationModal}
        </>
    );
}
