import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageNames } from 'utils';

export function ManagePackages() {
    return (
        <ScreenerHome
            cardName={PageNames.ManagePackages}
            modals={[
                {
                    props: {
                        title: 'Create Package',
                        submitButtonName: 'Create Package',
                        entityType: UserPlatformManagementEntityTypes.Package,
                        onOperationSuccessSnackMessage: 'Package successfully created',
                    },
                },
            ]}
            screenerRequestParams={{ path: 'package' }}
            searchPlaceholder='Search for Package'
            useSSRMode={false}
        />
    );
}
