import { useEffect, useRef } from 'react';

type UseIndependentProps<T> = {
    valueGetter: () => T;
    defaultValue: T;
}

export function useMakeIndependent<T>({ valueGetter, defaultValue }: UseIndependentProps<T>) {
    // this hook is useful when you want to keep the value updated
    // but you don't want to trigger re-renders
    // the default value is used until the valueGetter is called first time
    const ref = useRef<T>(defaultValue);

    useEffect(() => {
        ref.current = valueGetter();
    }, [valueGetter]);

    // the ref requires outside to include in the dependencies array whatever it is used
    // but actually it doesn't trigger re-renders
    return { independentValue: ref }
}
