import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from '../components/Home';

export function HomeRoutes() {
    return (
        <Routes>
            <Route path='' element={<Home />} />
            <Route path='*' element={<Navigate to='.' />} />
        </Routes>
    );
}
