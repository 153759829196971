export function getCursorVariant1(isLoading: boolean, isError: boolean) {
    if (isLoading) {
        return 'wait';
    }
    if (isError) {
        return 'not-allowed';
    }
    return 'pointer';
}

export function setCursor(cursor: string = 'all-scroll') {
    const rootElement = document.querySelector('#root') as HTMLDivElement;
    if (rootElement) {
        rootElement.style.cursor = cursor;
    }
}

export function resetCursor() {
    setCursor('unset');
}