import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import dayjs, { Dayjs } from 'dayjs';
import parse from 'html-react-parser';
import { InformationModal } from '../types/common';
import { ValueTypes } from './valuesFormatter';

export const enum assetClasses {
    EquitiesStocks = 'Equities (Stocks)',
    Bonds = 'Bonds',
    CommoditiesAndMetals = 'Commodities & Metals',
    Currency = 'Currency',
    OtherAssetTypes = 'Other Asset Types',
    TargetDateMultiAsset = 'Target Date / Multi-Asset',
}

export const enum SectionsNames {
    ETFDetailHeader = 'ETF Detail Header',
    ETFPerformance = 'ETF Performance',
    KeyStatistic = 'Key Statistic',
    TopHoldings = 'Top Holdings',
}

export enum DataPointsDisplayNames {
    Ticker = 'Ticker',
    Name = 'Name',
    Price = 'Price',
    CurrencyCode = 'Currency Code',
    PriceChange = 'Price Change',
    PricePercentagesChange = 'Price % change',
    AsOfDate = 'As of date',
    PublishablePriceAsOfDate = 'Publishable Price As of date',
    PublishableNavAsOfDate = 'Publishable Nav As of date',
    LatestPriceAsOfDate = 'Latest Price As of date',
    LatestNavAsOfDate = 'Latest Nav As of date',
    AssetClass = 'Asset Class',
    AssetClassId = 'Asset Class Id',
    NetAssets = 'Net Assets',
    ExpenseRatio = 'Expense Ratio',
    OneMonthReturn = '1 Month Return',
    YTDReturn = 'YTD Return',
    ThreeMonthReturn = '3 Month Return',
    OneYearReturn = '1 Year Return',
    ThreeYearReturn = '3 Year Return',
    FiveYearReturn = '5 Year Return',
    TenYearReturn = '10 Year Return',
    SinceInception = 'Since Inception',
    Description = 'Description',
    RegionOfListing = 'Region of Listing',
    Exchange = 'Exchange',
    CFRACategoryOne = 'CFRA Category',
    CFRACategoryOneId = 'CFAR Category Id',
    CFRACategoryTwo = 'CFRA Sub-category',
    CFRACategoryTwoId = 'CFRA Sub-category Id',
    MarketCapRange = 'Market Cap. Range',
    MarketCapRangeId = 'Market Cap. Range Id',
    BondType = 'Bond Type',
    BondTypeId = 'Bond Type Id',
    CreditQuality = 'Credit Quality',
    GrowthValueTilt = 'Growth Value Tilt',
    GrowthValueTiltId = 'Growth Value Tilt Id',
    AverageMaturity = 'Average Maturity',
    CommodityType = 'Commodity Type',
    CommodityTypeId = 'Commodity Type Id',
    AssetType = 'Asset Type',
    CurrencyPair = 'Currency Pair',
    CurrencyPairId = 'Currency Pair Id',
    MultiAssetType = 'Multi-Asset Type',
    IndexWeightedScheme = 'Index Weighting Methodology',
    InceptionDate = 'Inception Date',
    LegalStructure = 'Legal Structure',
    LegalStructureId = 'Legal Structure Id',
    Sponsor = 'Sponsor',
    FundFamily = 'Fund Family',
    UnderlyingIndex = 'Underlying Index',
    ETFWebsite = 'ETF Website',
    SmartTraditionalBeta = 'Smart - Traditional Beta',
    RegionOfExposure = 'Region of Exposure',
    NumberOfHoldings = 'Number of Holdings',
    FiftyTwoWeekRange = '52-Week Range',
    PremiumDiscount = 'Premium/Discount',
    BidAskSpread = 'Bid/Ask Spread',
    SharesOutstanding = 'Shares Outstanding',
    OneMonthAverageVolume = '1 Month Average Volume',
    Turnover = 'Turnover',
    TwelveMonthYield = '12 Month Yield',
    ThreeMonthsReturn = '3 Months Return',
    OneMonthFlows = '1 Month Flows',
    ThreeMonthFlows = '3 Month Flows',
    YTDFlows = 'YTD Flows',
    OneYearFlows = '1 Year Flows',
    AverageCoupon = 'Average Coupon',
    AverageDuration = 'Average Duration',
    PercentagesInvestmentGrade = '% Investment Grade',
    PercentagesSpeculativeGrade = '% Speculative Grade',
    PercentageOfETFAssets = '% of ETF Assets',
    ConstituentType = 'Constituent Type',
    Sector = 'Sector',
    PercentageOfTheTop10Holdings = '% of the top 10 holdings',
    Cusip = 'Cusip',
    MaturityDate = 'Maturity Date',
    Coupon = 'Coupon',
    TickerCusip = 'Ticker/Cusip',
    TotalsRow = 'Total weight % of the top {top} holdings',
    CfraID = 'CFRA ID',
    STARSCurrentRank = 'STARS Rating',
}

const specificDataPointsDisplayNames: { [id: string]: { [id: string]: string } } = {};

export function getDataPointsDisplayNames(section?: SectionsNames) {
    if (typeof section === 'undefined') return DataPointsDisplayNames;

    if (!specificDataPointsDisplayNames.hasOwnProperty(section)) {
        //console.error(`SpecificDataPointsDisplayNames doesn't contain section ${section}`);
        return DataPointsDisplayNames;
    }

    return { ...DataPointsDisplayNames, ...specificDataPointsDisplayNames[section] };
}

const dataPointDisplayNameToFieldName: Record<DataPointsDisplayNames, string> = {
    [DataPointsDisplayNames.Ticker]: 'ticker',
    [DataPointsDisplayNames.Name]: 'composite_name',
    [DataPointsDisplayNames.Price]: 'close_price_split_dividend_adjusted',
    [DataPointsDisplayNames.CurrencyCode]: 'listing_currency',
    [DataPointsDisplayNames.PriceChange]: 'close_price_split_dividend_adjusted_diff',
    [DataPointsDisplayNames.PricePercentagesChange]: 'close_price_split_dividend_adjusted_percent_change',
    [DataPointsDisplayNames.AsOfDate]: 'as_of_date',
    [DataPointsDisplayNames.PublishablePriceAsOfDate]: 'publishable_price_as_of_date',
    [DataPointsDisplayNames.PublishableNavAsOfDate]: 'publishable_nav_as_of_date',
    [DataPointsDisplayNames.LatestPriceAsOfDate]: 'latest_price_as_of_date',
    [DataPointsDisplayNames.LatestNavAsOfDate]: 'latest_nav_as_of_date',
    [DataPointsDisplayNames.AssetClass]: 'asset_class',
    [DataPointsDisplayNames.AssetClassId]: 'asset_class_lid',
    [DataPointsDisplayNames.NetAssets]: 'total_net_assets',
    [DataPointsDisplayNames.ExpenseRatio]: 'net_expense_ratio',
    [DataPointsDisplayNames.OneMonthReturn]: 'return_split_and_dividend_one_month',
    [DataPointsDisplayNames.YTDReturn]: 'split_and_dividend_ytd_return',
    [DataPointsDisplayNames.ThreeMonthReturn]: 'month_3_return',
    [DataPointsDisplayNames.OneYearReturn]: 'year_1_return',
    [DataPointsDisplayNames.ThreeYearReturn]: 'year_3_return',
    [DataPointsDisplayNames.FiveYearReturn]: 'year_5_return',
    [DataPointsDisplayNames.TenYearReturn]: 'year_10_return',
    [DataPointsDisplayNames.SinceInception]: 'since_inception',
    [DataPointsDisplayNames.Description]: 'etf_description',
    [DataPointsDisplayNames.RegionOfListing]: 'listing_region',
    [DataPointsDisplayNames.Exchange]: 'exchange_name',
    [DataPointsDisplayNames.CFRACategoryOne]: 'single_category_designation',
    [DataPointsDisplayNames.CFRACategoryOneId]: 'single_category_designation_lid',
    [DataPointsDisplayNames.CFRACategoryTwo]: 'level_2_name',
    [DataPointsDisplayNames.CFRACategoryTwoId]: 'single_category_level_2_short_lid',
    [DataPointsDisplayNames.MarketCapRange]: 'market_cap_range',
    [DataPointsDisplayNames.MarketCapRangeId]: 'market_cap_range_lid',
    [DataPointsDisplayNames.BondType]: 'bond_type',
    [DataPointsDisplayNames.BondTypeId]: 'bond_type_lid',
    [DataPointsDisplayNames.CreditQuality]: 'credit_quality',
    [DataPointsDisplayNames.GrowthValueTilt]: 'growth_value_tilt',
    [DataPointsDisplayNames.GrowthValueTiltId]: 'growth_value_tilt_lid',
    [DataPointsDisplayNames.AverageMaturity]: 'average_maturity',
    [DataPointsDisplayNames.CommodityType]: 'commodity_types',
    [DataPointsDisplayNames.CommodityTypeId]: 'commodity_types_lid',
    [DataPointsDisplayNames.AssetType]: 'other_asset_types',
    [DataPointsDisplayNames.CurrencyPair]: 'currency_pair',
    [DataPointsDisplayNames.CurrencyPairId]: 'currency_pair_lid',
    [DataPointsDisplayNames.MultiAssetType]: 'target_date_multi_asset_type',
    [DataPointsDisplayNames.IndexWeightedScheme]: 'index_weighting_scheme',
    [DataPointsDisplayNames.InceptionDate]: 'inception_date',
    [DataPointsDisplayNames.LegalStructure]: 'legal_structure',
    [DataPointsDisplayNames.LegalStructureId]: 'legal_structure_lid',
    [DataPointsDisplayNames.Sponsor]: 'sponsor',
    [DataPointsDisplayNames.FundFamily]: 'fund_family',
    [DataPointsDisplayNames.UnderlyingIndex]: 'index_name',
    [DataPointsDisplayNames.ETFWebsite]: 'website_link',
    [DataPointsDisplayNames.SmartTraditionalBeta]: 'smart_vs_traditional_beta',
    [DataPointsDisplayNames.FiftyTwoWeekRange]: '52-Week Range',
    [DataPointsDisplayNames.PremiumDiscount]: 'premium_discount',
    [DataPointsDisplayNames.BidAskSpread]: 'bid_ask_spread',
    [DataPointsDisplayNames.SharesOutstanding]: 'share_outstanding_split_adjusted',
    [DataPointsDisplayNames.OneMonthAverageVolume]: 'average_daily_volume_one_month',
    [DataPointsDisplayNames.NumberOfHoldings]: 'holdings_count',
    [DataPointsDisplayNames.Turnover]: 'etf_portfolio_turnover',
    [DataPointsDisplayNames.TwelveMonthYield]: 'ttm_distribution_yield',
    [DataPointsDisplayNames.ThreeMonthsReturn]: 'return_split_and_dividend_three_month',
    [DataPointsDisplayNames.OneMonthFlows]: 'net_flows_one_month',
    [DataPointsDisplayNames.ThreeMonthFlows]: 'net_flows_three_month',
    [DataPointsDisplayNames.YTDFlows]: 'net_flows_ytd',
    [DataPointsDisplayNames.OneYearFlows]: 'net_flows_one_year',
    [DataPointsDisplayNames.AverageCoupon]: 'average_coupon',
    [DataPointsDisplayNames.AverageDuration]: 'average_duration',
    [DataPointsDisplayNames.PercentagesInvestmentGrade]: 'investment_grade_percent',
    [DataPointsDisplayNames.PercentagesSpeculativeGrade]: 'speculative_grade_percent',
    [DataPointsDisplayNames.PercentageOfETFAssets]: 'weighting_sponsor',
    [DataPointsDisplayNames.ConstituentType]: 'constituent_type',
    [DataPointsDisplayNames.Sector]: 'gics_sector_name',
    [DataPointsDisplayNames.PercentageOfTheTop10Holdings]: 'total_weight_percentage',
    [DataPointsDisplayNames.Cusip]: 'cusip',
    [DataPointsDisplayNames.MaturityDate]: 'maturity',
    [DataPointsDisplayNames.Coupon]: 'coupon',
    [DataPointsDisplayNames.TotalsRow]: 'total_weight_percentage',
    [DataPointsDisplayNames.TickerCusip]: '',
    [DataPointsDisplayNames.RegionOfExposure]: 'custom_region',
    [DataPointsDisplayNames.CfraID]: 'cfra_id',
    [DataPointsDisplayNames.STARSCurrentRank]: 'stars_current_rank',
};

const specificDataPointDisplayNameToFieldName: Partial<
    Record<SectionsNames, { [key in DataPointsDisplayNames]?: string }>
> = {
    [SectionsNames.ETFPerformance]: {
        [DataPointsDisplayNames.YTDReturn]: 'ytd_return',
        [DataPointsDisplayNames.OneMonthReturn]: 'month_1_return',
    },
    [SectionsNames.KeyStatistic]: {
        [DataPointsDisplayNames.OneYearReturn]: 'return_split_and_dividend_one_year',
    },
    [SectionsNames.TopHoldings]: {
        [DataPointsDisplayNames.Name]: 'constituent_name',
        [DataPointsDisplayNames.Ticker]: 'constituent_ticker',
        [DataPointsDisplayNames.YTDReturn]: 'ytd_return',
    },
};

export function getDataPointDisplayNameToFieldName(section?: SectionsNames) {
    if (typeof section === 'undefined') return dataPointDisplayNameToFieldName;

    if (!specificDataPointDisplayNameToFieldName.hasOwnProperty(section)) {
        //console.error(`SpecificDataPointDisplayNameToFieldName doesn't contain section ${section}`);
        return dataPointDisplayNameToFieldName;
    }

    return { ...dataPointDisplayNameToFieldName, ...specificDataPointDisplayNameToFieldName[section] };
}

const dataPointsDisplayNameToFormattingType: Record<DataPointsDisplayNames, ValueTypes> = {
    [DataPointsDisplayNames.Ticker]: ValueTypes.Text,
    [DataPointsDisplayNames.Name]: ValueTypes.Text,
    [DataPointsDisplayNames.Price]: ValueTypes.Currency,
    [DataPointsDisplayNames.CurrencyCode]: ValueTypes.Text,
    [DataPointsDisplayNames.PriceChange]: ValueTypes.Numeral,
    [DataPointsDisplayNames.PricePercentagesChange]: ValueTypes.ConvertedPercentage,
    [DataPointsDisplayNames.AsOfDate]: ValueTypes.Date,
    [DataPointsDisplayNames.PublishablePriceAsOfDate]: ValueTypes.Date,
    [DataPointsDisplayNames.PublishableNavAsOfDate]: ValueTypes.Date,
    [DataPointsDisplayNames.LatestPriceAsOfDate]: ValueTypes.Date,
    [DataPointsDisplayNames.LatestNavAsOfDate]: ValueTypes.Date,
    [DataPointsDisplayNames.AssetClass]: ValueTypes.Text,
    [DataPointsDisplayNames.AssetClassId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.NetAssets]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.ExpenseRatio]: ValueTypes.Percentage,
    [DataPointsDisplayNames.OneMonthReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.YTDReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.ThreeMonthReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.OneYearReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.ThreeYearReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.FiveYearReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.TenYearReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.SinceInception]: ValueTypes.Percentage,
    [DataPointsDisplayNames.Description]: ValueTypes.Text,
    [DataPointsDisplayNames.RegionOfListing]: ValueTypes.Text,
    [DataPointsDisplayNames.Exchange]: ValueTypes.Text,
    [DataPointsDisplayNames.CFRACategoryOne]: ValueTypes.Text,
    [DataPointsDisplayNames.CFRACategoryOneId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.CFRACategoryTwo]: ValueTypes.Text,
    [DataPointsDisplayNames.CFRACategoryTwoId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.MarketCapRange]: ValueTypes.Text,
    [DataPointsDisplayNames.MarketCapRangeId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.BondType]: ValueTypes.Text,
    [DataPointsDisplayNames.BondTypeId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.CreditQuality]: ValueTypes.Text,
    [DataPointsDisplayNames.GrowthValueTilt]: ValueTypes.Text,
    [DataPointsDisplayNames.GrowthValueTiltId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.AverageMaturity]: ValueTypes.Text,
    [DataPointsDisplayNames.CommodityType]: ValueTypes.Text,
    [DataPointsDisplayNames.CommodityTypeId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.AssetType]: ValueTypes.Text,
    [DataPointsDisplayNames.CurrencyPair]: ValueTypes.Text,
    [DataPointsDisplayNames.CurrencyPairId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.MultiAssetType]: ValueTypes.Text,
    [DataPointsDisplayNames.IndexWeightedScheme]: ValueTypes.Text,
    [DataPointsDisplayNames.InceptionDate]: ValueTypes.Date,
    [DataPointsDisplayNames.LegalStructure]: ValueTypes.Text,
    [DataPointsDisplayNames.LegalStructureId]: ValueTypes.Numeral,
    [DataPointsDisplayNames.Sponsor]: ValueTypes.Text,
    [DataPointsDisplayNames.FundFamily]: ValueTypes.Text,
    [DataPointsDisplayNames.UnderlyingIndex]: ValueTypes.Text,
    [DataPointsDisplayNames.ETFWebsite]: ValueTypes.Text,
    [DataPointsDisplayNames.SmartTraditionalBeta]: ValueTypes.Text,
    [DataPointsDisplayNames.NumberOfHoldings]: ValueTypes.Numeral,
    [DataPointsDisplayNames.FiftyTwoWeekRange]: ValueTypes.Special,
    [DataPointsDisplayNames.PremiumDiscount]: ValueTypes.Percentage,
    [DataPointsDisplayNames.BidAskSpread]: ValueTypes.Percentage,
    [DataPointsDisplayNames.SharesOutstanding]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.OneMonthAverageVolume]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.Turnover]: ValueTypes.Percentage,
    [DataPointsDisplayNames.TwelveMonthYield]: ValueTypes.Percentage,
    [DataPointsDisplayNames.ThreeMonthsReturn]: ValueTypes.Percentage,
    [DataPointsDisplayNames.OneMonthFlows]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.ThreeMonthFlows]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.YTDFlows]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.OneYearFlows]: ValueTypes.MillionsScale,
    [DataPointsDisplayNames.AverageCoupon]: ValueTypes.ConvertedPercentage,
    [DataPointsDisplayNames.AverageDuration]: ValueTypes.Numeral,
    [DataPointsDisplayNames.PercentagesInvestmentGrade]: ValueTypes.Percentage,
    [DataPointsDisplayNames.PercentagesSpeculativeGrade]: ValueTypes.Percentage,
    [DataPointsDisplayNames.PercentageOfETFAssets]: ValueTypes.Percentage,
    [DataPointsDisplayNames.ConstituentType]: ValueTypes.Text,
    [DataPointsDisplayNames.Sector]: ValueTypes.Text,
    [DataPointsDisplayNames.PercentageOfTheTop10Holdings]: ValueTypes.Percentage,
    [DataPointsDisplayNames.Cusip]: ValueTypes.Text,
    [DataPointsDisplayNames.MaturityDate]: ValueTypes.Date,
    [DataPointsDisplayNames.Coupon]: ValueTypes.ConvertedPercentage,
    [DataPointsDisplayNames.TickerCusip]: ValueTypes.Text,
    [DataPointsDisplayNames.TotalsRow]: ValueTypes.Percentage,
    [DataPointsDisplayNames.RegionOfExposure]: ValueTypes.Text,
    [DataPointsDisplayNames.CfraID]: ValueTypes.Text,
    [DataPointsDisplayNames.STARSCurrentRank]: ValueTypes.Integer,
};

const specificDataPointsDisplayNameToFormattingType: { [id: string]: { [id: string]: ValueTypes } } = {
    [SectionsNames.ETFDetailHeader]: {
        [DataPointsDisplayNames.Price]: ValueTypes.Numeral,
    },
    [SectionsNames.TopHoldings]: {
        [DataPointsDisplayNames.YTDReturn]: ValueTypes.ConvertedPercentage,
    },
};

export function getDataPointsDisplayNameToFormattingType(section?: SectionsNames) {
    if (typeof section === 'undefined') return dataPointsDisplayNameToFormattingType;

    if (!specificDataPointsDisplayNameToFormattingType.hasOwnProperty(section)) {
        //console.error(`dataPointsDisplayNameToFormattingType doesn't contain section ${section}`);
        return dataPointsDisplayNameToFormattingType;
    }

    return { ...dataPointsDisplayNameToFormattingType, ...specificDataPointsDisplayNameToFormattingType[section] };
}

export const enum MaturityExposureRanges {
    LessThanOneYear = '< 1 year',
    ShortFromOneToThreeYears = 'Short (1-3 year)',
    IntermediateFromThreeToTenYears = 'Intermediate (3-10 years)',
    LongTenPlusYears = 'Long (10+ years)',
    Other = 'Other',
    Unclassified = 'Unclassified',
}

export enum CreditExposureRatings {
    AAA = 'AAA',
    AA = 'AA',
    AAPlus = 'AA+',
    AAMinus = 'AA-',
    A = 'A',
    APlus = 'A+',
    AMinus = 'A-',
    BBB = 'BBB',
    BBBPlus = 'BBB+',
    BBBMinus = 'BBB-',
    BB = 'BB',
    BBPlus = 'BB+',
    BBMinus = 'BB-',
    B = 'B',
    BPlus = 'B+',
    BMinus = 'B-',
    CCC = 'CCC',
    CCCPlus = 'CCC+',
    CCCMinus = 'CCC-',
    CC = 'CC',
    C = 'C',
    D = 'D',
    NR = 'NR',
    BelowB = 'Below B',
    NotRated = 'Not Rated',
    Other = 'Other',
    Unclassified = 'Unclassified',
}

export const enum CreditExposureDisplayRatings {
    AAA = 'AAA',
    AA = 'AA',
    A = 'A',
    BBB = 'BBB',
    BB = 'BB',
    B = 'B',
    BelowB = 'Below B',
    NotRated = 'Not Rated',
    Other = 'Other',
    Unclassified = 'Unclassified',
}

export const CreditExposureRatingsToCreditExposureDisplayRatings: Record<
    CreditExposureRatings,
    CreditExposureDisplayRatings
> = {
    [CreditExposureRatings.AAA]: CreditExposureDisplayRatings.AAA,
    [CreditExposureRatings.AA]: CreditExposureDisplayRatings.AA,
    [CreditExposureRatings.AAPlus]: CreditExposureDisplayRatings.AA,
    [CreditExposureRatings.AAMinus]: CreditExposureDisplayRatings.AA,
    [CreditExposureRatings.A]: CreditExposureDisplayRatings.A,
    [CreditExposureRatings.APlus]: CreditExposureDisplayRatings.A,
    [CreditExposureRatings.AMinus]: CreditExposureDisplayRatings.A,
    [CreditExposureRatings.BBB]: CreditExposureDisplayRatings.BBB,
    [CreditExposureRatings.BBBPlus]: CreditExposureDisplayRatings.BBB,
    [CreditExposureRatings.BBBMinus]: CreditExposureDisplayRatings.BBB,
    [CreditExposureRatings.BB]: CreditExposureDisplayRatings.BB,
    [CreditExposureRatings.BBPlus]: CreditExposureDisplayRatings.BB,
    [CreditExposureRatings.BBMinus]: CreditExposureDisplayRatings.BB,
    [CreditExposureRatings.B]: CreditExposureDisplayRatings.B,
    [CreditExposureRatings.BPlus]: CreditExposureDisplayRatings.B,
    [CreditExposureRatings.BMinus]: CreditExposureDisplayRatings.B,
    [CreditExposureRatings.CCC]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.CCCPlus]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.CCCMinus]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.CC]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.C]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.D]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.NR]: CreditExposureDisplayRatings.NotRated,
    [CreditExposureRatings.BelowB]: CreditExposureDisplayRatings.BelowB,
    [CreditExposureRatings.NotRated]: CreditExposureDisplayRatings.NotRated,
    [CreditExposureRatings.Other]: CreditExposureDisplayRatings.Other,
    [CreditExposureRatings.Unclassified]: CreditExposureDisplayRatings.Unclassified,
};

export const enum Sectors {
    CommunicationServices = 'Communication Services',
    Energy = 'Energy',
    Utilities = 'Utilities',
    Materials = 'Materials',
    ConsumerDiscretionary = 'Consumer Discretionary',
    InformationTechnology = 'Information Technology',
    HealthCare = 'Health Care',
    RealEstate = 'Real Estate',
    Industrials = 'Industrials',
    Financials = 'Financials',
    ConsumerStaples = 'Consumer Staples',
    Other = 'Other',
    Unclassified = 'Unclassified',
}

export enum MarketTrendsDateRanges {
    YTD = 'YTD',
    OneMonth = '1M',
    ThreeMonth = '3M',
    OneYear = '1Y',
}

export const marketTrendsDateRangesToDisplayString: Record<MarketTrendsDateRanges, string> = {
    [MarketTrendsDateRanges.YTD]: 'YTD',
    [MarketTrendsDateRanges.OneMonth]: '1 Month',
    [MarketTrendsDateRanges.ThreeMonth]: '3 Month',
    [MarketTrendsDateRanges.OneYear]: '1 Year',
};

export const dateRangeToFlowToAssetRatioDataPoint: Record<MarketTrendsDateRanges, string> = {
    [MarketTrendsDateRanges.YTD]: 'flows_to_assets_ratio_ytd',
    [MarketTrendsDateRanges.OneMonth]: 'flows_to_assets_ratio_one_month',
    [MarketTrendsDateRanges.ThreeMonth]: 'flows_to_assets_ratio_three_month',
    [MarketTrendsDateRanges.OneYear]: 'flows_to_assets_ratio_one_year',
};

export const dateRangeToNetFlowsDataPoint: Record<MarketTrendsDateRanges, string> = {
    [MarketTrendsDateRanges.YTD]: 'net_flows_ytd',
    [MarketTrendsDateRanges.OneMonth]: 'net_flows_one_month',
    [MarketTrendsDateRanges.ThreeMonth]: 'net_flows_three_month',
    [MarketTrendsDateRanges.OneYear]: 'net_flows_one_year',
};

export const dateRangeToReturnSplitAndDividendDataPoint: Record<MarketTrendsDateRanges, string> = {
    [MarketTrendsDateRanges.YTD]: 'split_and_dividend_ytd_return',
    [MarketTrendsDateRanges.OneMonth]: 'return_split_and_dividend_one_month',
    [MarketTrendsDateRanges.ThreeMonth]: 'return_split_and_dividend_three_month',
    [MarketTrendsDateRanges.OneYear]: 'return_split_and_dividend_one_year',
};

export enum DateRanges {
    OneWeek = '1W',
    OneMonth = '1M',
    ThreeMonth = '3M',
    SixMonth = '6M',
    OneYear = '1Y',
    ThreeYears = '3Y',
    FiveYears = '5Y',
}

export const enum Frequencies {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum Categories {
    AssetClass = 'Asset Class',
    CategoryOne = 'CFRA Category',
    CategoryTwo = 'CFRA Sub-category',
}

export const categoriesToAggregateField: Record<Categories, string> = {
    [Categories.AssetClass]: 'asset_class',
    [Categories.CategoryOne]: 'cfra_level_1',
    [Categories.CategoryTwo]: 'cfra_level_2',
};

export const categoriesToResponceCategoryField: Record<Categories, string> = {
    [Categories.AssetClass]: 'asset_class',
    [Categories.CategoryOne]: 'cfra_level_1',
    [Categories.CategoryTwo]: 'level_2_name',
};

export const categoriesToResponceLidField: Record<Categories, string> = {
    [Categories.AssetClass]: 'asset_class_lid',
    [Categories.CategoryOne]: 'single_category_level_1_short_lid',
    [Categories.CategoryTwo]: 'single_category_level_2_short_lid',
};

export const enum Environments {
    Development = 'development',
    Local = 'local',
    Production = 'production',
    UAT = 'uat',
}

export const CategoryToLidDbFieldName: Record<Categories, string> = {
    [Categories.AssetClass]: 'asset_class_lid',
    [Categories.CategoryOne]: 'single_category_lid',
    [Categories.CategoryTwo]: 'single_category_level_2_short_lid',
};

export enum chartTypes {
    FlowsToAssetsDetails = 'flows_to_assets_details',
    PerformanceDetails = 'performance_details',
    KeyAssetClassesDetails = 'key_asset_class_details',
    SectorPerformanceDetails = 'sector_performance_details',
    SectorExposureOverTime = 'sector_exposure_over_time',
    SectorExposure = 'sector_exposure',
    CreditExposureOvertime = 'credit_exposure_over_time',
    CreditExposure = 'credit_exposure',
    MaturityExposure = 'maturity_exposure',
    FundFlows = 'fund_flows',
    FundVsMarketMovement = 'fund_vs_market_movement',
}

export const valuesTypesToExcelNumberFormat: Partial<Record<ValueTypes, string | undefined>> = {
    [ValueTypes.Percentage]: '#,##0.00%',
    [ValueTypes.ConvertedPercentage]: '#,##0.00"%"',
    [ValueTypes.PercentageOnlyNumber]: '#,##0.00',
    [ValueTypes.Numeral]: '#,##0.00',
    [ValueTypes.NumeralAlwaysFloat]: '#,##0.00',
    [ValueTypes.Currency]: '#,##0.00',
    [ValueTypes.Integer]: '#,##0',
    [ValueTypes.MillionsScale]: '#,##0.00"M"',
    [ValueTypes.MillionsWithForceScale]: '#,##0.00,,',
};

export const keyToDateRangePickerLabelValue: Record<number, PickersShortcutsItem<DateRange<Dayjs>>> = {
    1: {
        label: 'YTD',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('year'), today];
        },
    },
    2: {
        label: '1Y',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, 'year'), today];
        },
    },
    3: {
        label: '3Y',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(3, 'year'), today];
        },
    },
    4: {
        label: '5Y',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(5, 'year'), today];
        },
    },
    5: {
        label: '1 Week',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, 'week'), today];
        },
    },
    6: {
        label: '1 Month',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(1, 'month'), today];
        },
    },
    7: {
        label: '3 Months',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(3, 'months'), today];
        },
    },
    8: {
        label: '6 Months',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(6, 'months'), today];
        },
    },
    9: {
        label: '12 Months',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(12, 'months'), today];
        },
    },
};

export const enum ApiNames {
    Fundynamix = 'Fundynamix',
    OpenSearch = 'OpenSearch',
    Research = 'Research',
    UserManagement = 'UserManagement',
    PlatformManagementInternal = 'PlatformManagementInternal',
    AI = 'AI',
}

export const scaleCharacterToNumber = {
    h: 100,
    k: 1_000,
    m: 1_000_000,
    b: 1_000_000_000,
};

export const enum RequestTypes {
    DELETE = 'DELETE',
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

export const enum UserSavedItemsLookupID {
    ETFScreenerSavedScreen = 1,
    ETFScreenerSavedView = 2,
    Watchlist = 3,
    InstitutionalWatchlist = 4,
    InstitutionalUserPreferences = 5,
}

export const enum ApiInstanceTypes {
    Axios = 'axios',
    AwsAmplify = 'awsAmplify',
}

export const enum LinkTargetTypes {
    CurrentTab = 'current_tab',
    NewTab = 'new_tab',
}

export const DataPointFieldNameToLinkValue: Record<string, string> = {
    [DataPointsDisplayNames.AssetClass]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.AssetClassId],
    [DataPointsDisplayNames.CFRACategoryOne]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.CFRACategoryOneId],
    [DataPointsDisplayNames.CFRACategoryTwo]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.CFRACategoryTwoId],
    [DataPointsDisplayNames.MarketCapRange]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.MarketCapRangeId],
    [DataPointsDisplayNames.GrowthValueTilt]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.GrowthValueTiltId],
    [DataPointsDisplayNames.Sponsor]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.Sponsor],
    [DataPointsDisplayNames.UnderlyingIndex]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.UnderlyingIndex],
    [DataPointsDisplayNames.LegalStructure]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.LegalStructureId],
    [DataPointsDisplayNames.CommodityType]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.CommodityTypeId],
    [DataPointsDisplayNames.BondType]: dataPointDisplayNameToFieldName[DataPointsDisplayNames.BondTypeId],
};

export const DataPointFieldNameToFilterKey: Record<string, string> = {
    [DataPointsDisplayNames.AssetClass]: 'etf_data.data_etf_universe.asset_class_lid',
    [DataPointsDisplayNames.CFRACategoryOne]: 'etf_data.data_etf_universe.single_category_lid',
    [DataPointsDisplayNames.CFRACategoryTwo]: 'etf_data.data_etf_universe.single_category_level_2_short_lid',
    [DataPointsDisplayNames.MarketCapRange]: 'etf_data.data_etf_universe.market_cap_range_lid',
    [DataPointsDisplayNames.GrowthValueTilt]: 'etf_data.data_etf_universe.growth_value_tilt_lid',
    [DataPointsDisplayNames.Sponsor]: 'etf_data.data_etf_universe.sponsor',
    [DataPointsDisplayNames.UnderlyingIndex]: 'etf_data.data_etf_universe.index_name',
    [DataPointsDisplayNames.LegalStructure]: 'etf_data.data_etf_universe.legal_structure_lid',
    [DataPointsDisplayNames.CommodityType]: 'etf_data.data_etf_universe.commodity_types_lid',
    [DataPointsDisplayNames.BondType]: 'etf_data.data_etf_universe.bond_type_lid',
};

export const enum UserPlatformManagementEntityTypes {
    Account = 'account',
    User = 'user',
    User_Alert_Subscription = 'user_alert_subscription',
    User_Group = 'user_group',
    Group = 'group',
    Package = 'package',
    Package_Entitlement = 'package_entitlement',
}

export const enum OperationTypes {
    EDIT = 'edit',
    CREATE = 'create',
    BULK_CREATE = 'bulk_create',
    BULK_EDIT = 'bulk_edit',
}

export const enum SectionKeys {
    CFRARatings = 'cfra_ratings',
    CFRARatingsTopHoldings = 'cfra_ratings_top_holdings',
}
export const SectionsWithInformation: Record<SectionKeys | string, InformationModal> = {
    [SectionKeys.CFRARatings]: {
        title: 'About CFRA Ratings',
        information: parse(`
                CFRA's ETF ratings assign both an overall star rating as well as three component scores for each ETF rated in the universe. CFRA uses a five-tiered star ratings approach for ETFs, which indicates how total return is expected to perform vis a vis the total return of a similar group of ETFs over the next nine months, where Five-Stars represents the strongest rating.
                <br />
                Ratings include 3 component scores:
                <ul style={{ paddingLeft: '14px', marginTop: '0px', marginBottom: '0px' }}>
                    <li>
                        <b>Cost:</b> The cost component score is a relative percentile ranking 0-100 comparing model parameters related to the total cost of ownership for the fund. A cost score is of 100 is most favorable and implies the fund is positioned favorably relative to a similar group of ETFs.
                    </li>
                    <li>
                        <b>Reward:</b> The reward component score is a relative percentile ranking 0-100 comparing model parameters related to the ETF's central tendency of expected return. A high reward score of 100 is most favorable and implies the fund is positioned favorably relative to a similar group of ETFs.
                    </li>
                    <li>
                        <b>Risk:</b> The risk component score is a relative percentile ranking 0-100 comparing model parameters related to the ETF's negative tail risk or ETF's likelihood of extreme losses. A risk score is of 100 is most favorable and implies the fund is positioned favorably relative to a similar group of ETFs.
                    </li>
                </ul>
                <a href="https://services.marketscope.com/reports/aidata/dynamic/ETF%20Methodology_01272020.pdf" target="_blank" style="color:#007bb8; textDecoration:none">Learn more about CFRA ETF Ratings</a>
            `),
    },
    [SectionKeys.CFRARatingsTopHoldings]: {
        title: 'About CFRA Ratings',
        information: parse(`
                CFRA’s fundamental analysis utilizes our proprietary STARS® (STock Appreciation Ranking System) methodology for over 1,600* global stocks. Our equity analysts carry on a strong tradition of objective analysis and opinion dating back over 80 years, using a highly refined, rigorous and globally-consistent process. The STARS ranking has five categories:
                <ul style={{ paddingLeft: '14px', marginTop: '0px', marginBottom: '0px' }}>
                    <li>
                        <b>5-STARS (Strong Buy): </b> Total return is expected to outperform the total return of a relevant benchmark by a wide margin over the coming 12 months, with shares rising in price on absolute basis.
                    </li>
                    <li>
                        <b>4-STARS (Buy): </b> Total return is expected to outperform the total return a relevant benchmark over the coming 12 months, with shares rising in price on an absolute basis.
                    </li>
                    <li>
                        <b>3-STARS (Hold): </b> Total return is expected to closely approximate the total return of a relevant benchmark over the coming 12 months, with shares generally rising in price on an absolute basis.
                    </li>
                    <li>
                        <b>2-STARS (Sell): </b> Total return is expected to underperform the total return of a relevant benchmark over the coming 12 months, and the share price is not anticipated to show a gain.
                    </li>
                    <li>
                        <b>1-STARS (Strong Sell): </b> Total return is expected to underperform the total return of a relevant benchmark by a wide margin over the coming 12 months, with shares falling in price on an absolute basis.
                    </li>
                </ul>
                Relevant benchmarks: In North America the relevant benchmark is the S&P 500® Index, in Europe and in Asia the relevant benchmarks are generally the S&P Europe 350® Index and the S&P Asia 50 Index. 
                <br/>
                <span style="font-size: 14px;"><i>*As of June 2024</i></spn>
            `),
    },
};

export const serverErrorToBeautifiedMessage: Record<string, string> = {
    "'user' already exists.": 'A user with that email already exists.',
    'data.email must be email': 'Invalid email format.',
};

export const enum UserAlertTypes {
    CapitolInsightsWelcomeEmail = 2,
}

export const FileTypeToReactDropzoneAccept: Record<string, Record<string, Array<string>>> = {
    xlsx: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
    csv: { 'text/csv': ['.csv'] },
};

export const enum OperateEntityStatuses {
    SUCCESS = 'success',
    FAILED = 'failed',
}

export const enum CellRendererComponentFilters {
    IS_LAST_ROLLING_HOURS = 'is_last_rolling_hours',
}

export const enum UsageRequestTypes {
    CompanyViewed = 'company_viewed',
    ReportViewed = 'report_viewed',
    PdfViewerViewed = 'pdf_viewer_viewed',
    SearchPerformed = 'search_performed',
}

export const enum ResearchTypeId {
    LegalReport = 15,
    LegalAlert = 16,
    LegalEducational = 17,
    PolicyNewsletterToWealth = 18,
    FrsCompanyReport = 19,
    FrsNotebooks = 20,
    FrsEarningsAlert = 21,
    FrsIndustryReport = 22,
    FrsEducationalReport = 23,
    FrsIRAPS = 24,
    FrsBanksOrFIReport = 25,
    FrsDailySnapshot = 26,
    LegalPipeline = 27,
    LegalPipelineSpecialInterestList = 28,
    FrsBespokeEdge = 29,
    FrsBespokeAdminViewer = 30,
    FrsDiagnosticReport = 31,
}

export const enum ProductLid {
    FUNDynamix = 1,
    CapitolInsights = 2,
    ForensicAccountingResearch = 3,
}

export const enum UsageTypeLid {
    PageView = 1,
    Search = 2,
}

export const enum UsageSourceLid {
    Email = 1,
}

export const enum EntityTypeLid {
    Research = 1,
    Equity = 2,
    ETF = 3,
    MF = 4,
    GICS = 5,
    Topic = 6,
    Company = 7,
}

export enum ApplicationType {
    InstitutionalPortal = 'institutional_portal',
    FUNDynamix = 'fundynamix',
    PlatformManagement = 'platform_management',
}

export enum LookupItems {
    ResearchTypeId = 1,
    RegionLid = 2,
    GICSCode = 3,
    ScoreTypeEarningsScore = 4,
    ScoreTypeCashFlow = 5,
    IsBiggestConcern = 6,
    MarketCap = 7,
}

export enum ComponentLid {
    GlobalSearch = 1,
    LocalSearch = 2,
}

export enum TimeRanges {
    Today = 'Today',
    OneWeek = '1 Week',
    OneMonth = '1 Month',
}

export const TimeRangesToHours: Record<TimeRanges, number> = {
    [TimeRanges.Today]: 24,
    [TimeRanges.OneWeek]: 7 * 24,
    [TimeRanges.OneMonth]: 30 * 24,
};
