import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ButtonBase, styled } from '@mui/material';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useMakeIndependent } from '../../hooks/useMakeIndependent';
import { fullTopNavigationHeight } from '../../utils';
import './AgGrid.scss';
import './paddings.scss';
import { debounce } from 'lodash';
import { getClosestNotVisibleColumn, scrollOneColumnTo } from './utils';

type AgGridHorizontalScrollArrowsProps = {
    gridRef: React.RefObject<AgGridReact<any>>;
    gridContainerRef: React.RefObject<HTMLDivElement>;
};

export type AgGridHorizontalScrollArrowsRef = {
    updateArrowsVerticalPositions: () => void;
    // show / hide horizontal scroll indicators
    refreshHorizontalScrollIndicators: () => void;
};

const iconHeight = 24;

function getarrowsVerticalPositionIncludingIconHeight(
    arrowsVerticalPosition: number | null,
    gridContainerRef: React.RefObject<HTMLDivElement>,
) {
    if (!arrowsVerticalPosition) {
        return null;
    }

    // center icon vertically
    const resultPosition = arrowsVerticalPosition - iconHeight / 2;

    if (resultPosition < 0) {
        // if less than 0, locate at the top
        return 0;
    }

    const containerHeight = gridContainerRef.current?.getBoundingClientRect().height || 0;
    // prefent container overflow at the bottom
    const maximumHeightWhereIconCanBeLocated = containerHeight - iconHeight;

    if (resultPosition > maximumHeightWhereIconCanBeLocated) {
        return containerHeight - iconHeight;
    }

    return resultPosition;
}

function getVisibleCenterOfElementFromTop(rect: DOMRect) {
    const viewportHeight = window.innerHeight - fullTopNavigationHeight;
    const top = rect.top - fullTopNavigationHeight;
    const bottom = rect.bottom - fullTopNavigationHeight;

    const isTopVisible = top >= 0 && top <= viewportHeight;
    const isBottomVisible = bottom <= viewportHeight && bottom >= 0;

    if (isTopVisible && isBottomVisible) {
        // the grid is fully within the viewport
        return rect.height / 2;
    }

    if (
        !isTopVisible &&
        !isBottomVisible &&
        // whole grid is fully above or fully below the viewport
        ((top < 0 && bottom < 0) || (top > viewportHeight && bottom > viewportHeight))
    ) {
        // no center position because the grid is not visible
        return null;
    }

    // !isTopVisible && !isBottomVisible - both top and bottom of the grid is not visible and whole viwport is busy by part of the grid
    // OR
    // !isTopVisible && isBottomVisible - top of the grid is not visible and bottom is visible
    // OR
    // isTopVisible && !isBottomVisible - top of the grid is visible and bottom is not visible
    const visibleHeight = Math.max(0, Math.min(viewportHeight, bottom) - Math.max(0, top));
    return visibleHeight / 2 + (isTopVisible ? 0 : Math.abs(top));
}

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '15px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: '#f6f7fa',
    '&:hover': {
        backgroundColor: '#e8e9ed',
    },
}));

enum ScrollPositions {
    Between = 'between',
    Left = 'left',
    Right = 'right',
}

export const AgGridHorizontalScrollArrows = forwardRef<
    AgGridHorizontalScrollArrowsRef,
    AgGridHorizontalScrollArrowsProps
>(({ gridRef, gridContainerRef }, ref) => {
    const [horizontalScrollPosition, setHorizontalScrollPosition] = useState<ScrollPositions | null>(null);
    const [arrowsVerticalPosition, setArrowsVerticalPosition] = useState<number | null>(null);

    const refreshHorizontalScrollIndicators = useCallback(() => {
        const hasScrollToRight = getClosestNotVisibleColumn(gridRef, 'next', 'partially_visible') !== null;
        const hasScrollToLeft = getClosestNotVisibleColumn(gridRef, 'previous', 'partially_visible') !== null;

        if (hasScrollToRight && hasScrollToLeft) {
            setHorizontalScrollPosition(ScrollPositions.Between);
            return;
        }

        if (hasScrollToRight) {
            setHorizontalScrollPosition(ScrollPositions.Right);
            return;
        }

        if (hasScrollToLeft) {
            setHorizontalScrollPosition(ScrollPositions.Left);
            return;
        }

        if (!hasScrollToRight && !hasScrollToLeft) {
            setHorizontalScrollPosition(null);
        }
    }, [gridRef]);

    const updateArrowsVerticalPositions = useCallback(() => {
        if (!gridContainerRef.current) {
            return;
        }

        const rect = gridContainerRef.current.getBoundingClientRect();
        const arrowsVerticalPosition = getVisibleCenterOfElementFromTop(rect);
        setArrowsVerticalPosition(arrowsVerticalPosition);
    }, [gridContainerRef]);

    const updateArrowsVerticalPositionsDebounced = useMemo(
        () => debounce(() => updateArrowsVerticalPositions(), 10),
        [updateArrowsVerticalPositions],
    );
    const refreshHorizontalScrollIndicatorsDebounced = useMemo(
        () => debounce(() => refreshHorizontalScrollIndicators(), 50),
        [refreshHorizontalScrollIndicators],
    );

    const { independentValue: updateArrowsVerticalPositionsDebouncedRef } = useMakeIndependent({
        valueGetter: () => () => updateArrowsVerticalPositionsDebounced(),
        defaultValue: () => {},
    });

    const { independentValue: refreshHorizontalScrollIndicatorsDebouncedRef } = useMakeIndependent({
        valueGetter: () => () => refreshHorizontalScrollIndicatorsDebounced(),
        defaultValue: () => {},
    });

    useEffect(() => {
        const handleScrollAndResize: () => void = () => {
            updateArrowsVerticalPositionsDebouncedRef.current();
            refreshHorizontalScrollIndicatorsDebouncedRef.current();
        };

        window.addEventListener('scroll', handleScrollAndResize, { passive: true });
        window.addEventListener('resize', handleScrollAndResize, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScrollAndResize);
            window.removeEventListener('resize', handleScrollAndResize);
        };
    }, [updateArrowsVerticalPositionsDebouncedRef, refreshHorizontalScrollIndicatorsDebouncedRef, gridContainerRef]);

    useImperativeHandle(ref, () => ({
        updateArrowsVerticalPositions: updateArrowsVerticalPositionsDebouncedRef.current,
        refreshHorizontalScrollIndicators: refreshHorizontalScrollIndicatorsDebouncedRef.current,
    }));

    const iconSx = useMemo(
        () => ({
            fill: '#398bf7',
            position: 'absolute',
            top: `${getarrowsVerticalPositionIncludingIconHeight(arrowsVerticalPosition, gridContainerRef)}px`,
            height: iconHeight,
        }),
        [arrowsVerticalPosition, gridContainerRef],
    );

    const handleLeftArrowClick = () => {
        scrollOneColumnTo('left', gridRef);
    };

    const handleRightArrowClick = () => {
        scrollOneColumnTo('right', gridRef);
    };

    if (!arrowsVerticalPosition) {
        return null; // don't render arrows if the grid is not visible
    }

    return (
        <>
            {([ScrollPositions.Between, ScrollPositions.Left] as Array<ScrollPositions | null>).includes(
                horizontalScrollPosition,
            ) && (
                <StyledButtonBase
                    onClick={handleLeftArrowClick}
                    sx={{
                        left: -1,
                        boxShadow: '6px 0px 6px 0px rgba(0, 0, 0, 0.1)',
                        clipPath: 'polygon(0% 0%, calc(100% + 10px) 0%, calc(100% + 10px) 100%, 0% 100%)',
                    }}>
                    <KeyboardArrowLeftIcon sx={iconSx} />
                </StyledButtonBase>
            )}
            {([ScrollPositions.Between, ScrollPositions.Right] as Array<ScrollPositions | null>).includes(
                horizontalScrollPosition,
            ) && (
                <StyledButtonBase
                    onClick={handleRightArrowClick}
                    sx={{
                        right: -1,
                        boxShadow: '-6px 0px 6px 0px rgba(0, 0, 0, 0.1)',
                        clipPath: 'polygon(calc(0% - 10px) 0%, 100% 0%, 100% 100%, calc(0% - 10px) 100%)',
                    }}>
                    <KeyboardArrowRightIcon sx={iconSx} />
                </StyledButtonBase>
            )}
        </>
    );
});
