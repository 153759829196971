import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { CreateModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import {
    RhFormData,
    getFormValueByFilterKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { RequestTypes, UserAlertTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

export function SendAlertWrapper({
    ModalComponent,
    ...props
}: { ModalComponent: React.FC<CreateModalProps> } & CreateModalProps) {
    const { sendAlert } = useContext(ProjectSpecificResourcesContext);
    const [userIds, setUserIds] = useState<Array<number> | undefined>();

    const sendAlertQuery = sendAlert?.({
        searchByParams: {
            requestBody: {
                users: userIds,
                alert_type: UserAlertTypes.CapitolInsightsWelcomeEmail,
            },
            config: {
                enabled: userIds !== undefined,
            },
        },
        requestType: RequestTypes.POST,
        invalidate: false,
    }) as UseQueryResult<any>;

    useEffect(() => {
        if (sendAlertQuery?.isSuccess && !sendAlertQuery.isLoading && sendAlertQuery?.data?.data) {
            setUserIds(undefined);
        }
    }, [sendAlertQuery]);

    function handleOnBeforeCreateSuccess(data: any | Array<any>, formData?: RhFormData, filtersData?: FiltersData) {
        const disableWelcomeEmailKey = 'user_management.user.disable_welcome_email';
        const userIds: Array<number> = [];

        const handleUserId = (item: any) => {
            const userId = item?.['user']?.id;
            if (typeof userId === 'number' && filtersData && formData) {
                const disableWelcomeEmail = getFormValueByFilterKey(disableWelcomeEmailKey, formData, filtersData);
                if (!!!disableWelcomeEmail) {
                    userIds.push(userId);
                }
            }
        };

        if (Array.isArray(data)) {
            data.forEach((item) => {
                handleUserId(item?.data);
            });
        } else {
            handleUserId(data);
        }

        if (userIds.length === 0) {
            return;
        }

        setUserIds(userIds);
    }

    return <ModalComponent {...props} onBeforeCreateSuccess={handleOnBeforeCreateSuccess} />;
}
