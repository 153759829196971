import { ProfilePage } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfilePage';
import { UserPlatformManagementEntityTypes, getValueByPath } from '@cfra-nextgen-frontend/shared/src/utils';

export function AccountProfile() {
    return (
        <ProfilePage
            entityType={UserPlatformManagementEntityTypes.Account}
            getProfileTitle={getAccountProfileTitle}
            editProfileButtonText='Edit Account'
        />
    );
}

function getAccountProfileTitle(data: any) {
    const defaultTitle: string = 'Account Profile';
    const company_name = getValueByPath(data, 'company_name') || '';
    return company_name || defaultTitle;
}
