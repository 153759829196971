import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';

type Props = {
    children: React.ReactNode;
    removeTopMargin?: boolean;
    containerStyles?: SxProps;
};

export function ETFPageContainer({ containerStyles, children }: Props) {
    const defaultContainerStyles = {
        maxWidth: '1366px',
        minHeight: '900px',
    };

    return (
        <Box sx={{ backgroundColor: '#f2f5fc' }}>
            <Container maxWidth={false} disableGutters sx={{ ...defaultContainerStyles, ...containerStyles }}>
                {children}
            </Container>
        </Box>
    );
}
