import { getIsActionMatch } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/reducers';
import { Action, State } from './types';

export function multipleModalsReducer(state: State, action: Action): State {
    const isActionMatch = getIsActionMatch(action.type);

    if (isActionMatch('complementModalsState')) {
        if (!action.newState?.modals) {
            throw new Error(
                'multipleModalsReducer exception. modals value is required for complementModalsState action.',
            );
        }

        Object.entries(action.newState?.modals).forEach(([modalId, modalState]) => {
            state = {
                ...state,
                modals: {
                    ...state.modals,
                    [modalId]: {
                        ...state.modals[modalId],
                        ...modalState,
                    },
                },
            };
        });
    }

    if (isActionMatch('resetModalsState')) {
        if (!action.newState?.modals) {
            throw new Error('multipleModalsReducer exception. modals value is required for resetModalsState action.');
        }

        Object.entries(action.newState?.modals).forEach(([modalId, modalState]) => {
            state = {
                ...state,
                modals: {
                    ...state.modals,
                    [modalId]:
                        modalState === undefined // allow reset modal state via passing undefined
                            ? modalState
                            : {
                                  ...state.modals[modalId],
                                  ...modalState,
                              },
                },
            };
        });
    }

    return state;
}
