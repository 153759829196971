import { AgGirdExportButton } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { SortModelItem } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { PlusIconTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/PlusIconTextButton';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { MainLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { AgGridExportProps } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext } from 'react';
import { IMetadataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';

export function ResultsAndExportRow({
    cardName,
    SSRDataExportFn,
    gridRef,
    fieldMetadata
}: {
    cardName: string;
    SSRDataExportFn?: (sortColumns: SortModelItem[]) => Promise<any>;
    gridRef: React.RefObject<AgGridReact<any>>;
    fieldMetadata?: IMetadataFields[]
}) {
    const { exportSSRAgGrid, exportAgGrid } = useContext(ProjectSpecificResourcesContext);
    const {
        chipStateManager: { chipState },
    } = useContext(ResultsContext);

    const getMessage = (count: number): string => {
        if (count === -1) {
            return 'Loading...';
        } else {
            return (count ? count.toString() : 'No') + ' results found';
        }
    };

    const getExportCallback = useCallback(
        (agGridExportProps: AgGridExportProps) => () => {
            if (!SSRDataExportFn) {
                throw new Error(
                    'ResultsAndExportRow exception. SSRDataExportFn and exportSSRAgGrid must be defined to use getExportCallback',
                );
            }
            exportSSRAgGrid?.(gridRef, SSRDataExportFn, agGridExportProps, fieldMetadata);
        },
        [exportSSRAgGrid, gridRef, SSRDataExportFn],
    );

    const exportButtonText = 'Export Data';

    return (
        <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            direction='row'
            borderTop='1px solid rgb(228, 229, 233)'>
            <Grid item>
                <MainLabel>{getMessage(chipState.resultCount)}</MainLabel>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item sx={{ padding: '10px 29px' }}>
                <AgGirdExportButton
                    label={cardName}
                    exportRef={gridRef}
                    getAnalyticsCallback={() => () =>
                        globalThis.analytics?.registerAction?.({
                            action: `click on ${exportButtonText}`,
                            cardName: cardName,
                        })}
                    getExportButton={(onClick) => (
                        <PlusIconTextButton buttonText={exportButtonText} onClickCallback={onClick} />
                    )}
                    disableMenu
                    {...(SSRDataExportFn ? { getExportCallback } : { exportAgGrid })}
                    getFormattedValuesFirst
                    fieldMetadata={fieldMetadata}
                />
            </Grid>
        </Grid>
    );
}
