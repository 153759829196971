import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { getFiltersReactNodes } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/getFiltersReactNodes';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { Dispatch, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import {
    Control,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form/dist/types/form';

export type PublicBasicFormProps = {
    analyticsCardName: string;
    filtersData: FiltersData;
    emitControlValues?: any;
    emitDefaultValues?: any;
};

type PrivateBasicFormProps = {
    analyticsCardName: string;
    control: Control<any>;
    filtersData: FiltersData;
    getValues: UseFormGetValues<FieldValues>;
    handleSubmit: UseFormHandleSubmit<any, undefined>;
    setFormData: Dispatch<RhFormData | undefined>;
    setValue: UseFormSetValue<FieldValues>;
    trigger: UseFormTrigger<any>;
    onChangeClearHandler?: (fieldName?: string) => void;
};

export function BasicForm({
    analyticsCardName,
    control,
    filtersData,
    getValues,
    handleSubmit,
    setFormData,
    setValue,
    trigger,
    onChangeClearHandler = (fieldName) => {},
    emitControlValues,
    emitDefaultValues,
}: PublicBasicFormProps & PrivateBasicFormProps) {
    const submitHandler: () => void = useCallback(() => {
        handleSubmit((data) => setFormData(data))();
    }, [handleSubmit, setFormData]);

    const validate: (fieldName: string) => Promise<boolean | undefined> = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    const reactNodes: Array<React.ReactNode> | null = useMemo(() => {
        if (!filtersData || (filtersData && filtersData.isErroredResponse)) {
            return null;
        }

        return getFiltersReactNodes({
            filtersData,
            control,
            analyticsCardName,
            getValues,
            setValue,
            validate,
            submitHandler,
            setHasSearchMatch: () => {},
            fieldDefaultStyles: { width: '90%' },
            onChangeClearHandler,
            emitControlValues,
            emitDefaultValues,
        });
    }, [
        analyticsCardName,
        control,
        filtersData,
        getValues,
        setValue,
        validate,
        submitHandler,
        onChangeClearHandler,
        emitControlValues,
        emitDefaultValues,
    ]);

    return (
        <form
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'hidden',
            }}>
            {reactNodes}
        </form>
    );
}
