import '@aws-amplify/ui-react/styles.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { LicenseManager } from 'ag-grid-enterprise';
import './App.scss';
import { AppProvider } from './providers/app';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENT_LICENSE as string);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE as string);

function App() {
    return (
        <div className='App'>
            <AppProvider />
        </div>
    );
}

export default App;
