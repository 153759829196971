
export enum AppRoutePaths {
    Home = '*',
    Entitlements = '/entitlements',
    Users = '/users',
    ManageAccounts = '/manage-accounts',
    ManageUsers = '/manage-users',
    ManagePackages = '/manage-packages',
    Package = '/package/:id',
    Account = '/account/:id',
    User = '/user/:id',
    Group = '/account/:account_id/group/:id'
}

export enum PageNames {
    Home = 'Home',
    Entitlements = 'Entitlements',
    Users = 'Users',
    ManageAccounts = 'Manage Accounts',
    ManageUsers = 'Manage Users',
    ManagePackages = 'Manage Packages',
    Package = 'Package',
    Account = 'Account Profile',
    User = 'User Profile',
    Group = 'Manage Group'
}
