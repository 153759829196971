import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ProfilePage } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfilePage';
import { ProfileForm, ProfileFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/ProfileForm';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    RequestTypes,
    UserPlatformManagementEntityTypes,
    getValueByPath,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

export function UserProfile() {
    return (
        <ProfilePage
            entityType={UserPlatformManagementEntityTypes.User}
            getProfileTitle={getUserProfileTitle}
            editProfileButtonText='Edit User'
            getUpdateConfirmTitle={getUpdateConfirmTitle}
            CustomProfileForm={UserCustomProfileForm}
        />
    );
}

function getUserProfileTitle(data: any, defaultTitle: string = 'User Profile') {
    const first_name = getValueByPath(data, 'first_name') || '';
    const last_name = getValueByPath(data, 'last_name') || '';

    if (first_name || last_name) {
        return `${first_name} ${last_name}`;
    }

    return defaultTitle;
}

function getUpdateConfirmTitle(data: any) {
    return `Are you sure you want to update ${getUserProfileTitle(data, '')}?`;
}

function UserCustomProfileForm(props: ProfileFormProps) {
    const [companyName, setCompanyName] = useState<string | undefined>();

    const modifyRequestBody = useCallback(
        (requestBody: any) => {
            if (requestBody['company_name']) {
                setCompanyName(requestBody['company_name']);
                delete requestBody['company_name'];
            }
            return requestBody;
        },
        [setCompanyName],
    );

    // TODO: This is a temporary solution to update user company,
    // need to refactor this once Api for updating user company is available (Update multiple entities in a single request)
    useUpdateUserCompany({
        companyName,
        userId: props.id,
        onSuccess: () => {
            setCompanyName(undefined);
        },
    });

    return <ProfileForm {...props} modifyRequestBody={modifyRequestBody} />;
}

const useUpdateUserCompany = (props: { companyName?: string; userId?: number; onSuccess: () => void }) => {
    const { companyName, userId, onSuccess } = props;
    const { getScreenerData, operateEntity } = useContext(ProjectSpecificResourcesContext);
    const [userGroupId, setUserGroupId] = useState<number | undefined>();
    const [groupId, setGroupId] = useState<number | undefined>();
    const [userHasAccount, setUserHasAccount] = useState<boolean | undefined>();

    // get groups associated with company
    const screenerGroupRes = getScreenerData?.({
        path: 'group',
        view: 'default',
        requestBody: {
            filters: {
                values: {
                    'user_management.account.company_name': {
                        values: [companyName],
                    },
                },
            },
        },
        config: {
            enabled: companyName !== undefined,
        },
    }) as UseQueryResult<ScreenerData>;

    // get user group associated with user
    const screenerUserGroupRes = getScreenerData?.({
        path: 'user_group',
        view: 'default',
        requestBody: {
            filters: {
                values: {
                    'user_management.user.id': {
                        values: [userId],
                    },
                },
            },
        },
        config: {
            enabled: userId !== undefined,
        },
    }) as UseQueryResult<ScreenerData>;

    // assumes single group per account
    useEffect(() => {
        if (!screenerGroupRes.isLoading && !screenerGroupRes.isError && screenerGroupRes?.data?.results?.data) {
            if (screenerGroupRes.data.results.data.length > 0) {
                setGroupId(screenerGroupRes.data.results.data[0].id);
            }
        }
    }, [screenerGroupRes?.data?.results?.data, screenerGroupRes.isError, screenerGroupRes.isLoading]);

    // assumes user is part of single account/single group
    useEffect(() => {
        if (
            !screenerUserGroupRes.isLoading &&
            !screenerUserGroupRes.isError &&
            screenerUserGroupRes?.data?.results?.data
        ) {
            if (screenerUserGroupRes.data.results.data.length > 0) {
                setUserGroupId(screenerUserGroupRes.data.results.data[0].id);
                setUserHasAccount(true);
            } else {
                setUserHasAccount(false);
                console.log('No user group found');
            }
        }
    }, [screenerUserGroupRes?.data?.results?.data, screenerUserGroupRes.isError, screenerUserGroupRes.isLoading]);

    //update user_group to associate with account
    const updateUserGroupQuery = operateEntity?.({
        searchByParams: {
            path: `user_group/${userGroupId}`,
            requestBody: {
                group_id: groupId,
            },
            config: {
                enabled: groupId !== undefined && userGroupId !== undefined,
            },
        },
        requestType: RequestTypes.PUT,
        invalidate: false,
    }) as UseQueryResult<any>;

    useEffect(() => {
        const refetchOperatedData = Boolean(
            updateUserGroupQuery &&
                !updateUserGroupQuery.isLoading &&
                updateUserGroupQuery.data &&
                Object.keys(updateUserGroupQuery.data).length > 0,
        );

        const validInputs = Boolean(companyName && typeof groupId === 'number' && typeof userGroupId === 'number');

        if (refetchOperatedData && validInputs) {
            updateUserGroupQuery?.remove();
            setUserHasAccount(undefined);
            setGroupId(undefined);
            setUserGroupId(undefined);
            onSuccess();
        }
    }, [updateUserGroupQuery, companyName, groupId, userGroupId, onSuccess]);

    //create user_group to associate with new account if user has no account
    const createUserGroupQuery = operateEntity?.({
        searchByParams: {
            path: `user_group`,
            requestBody: {
                group_id: groupId,
                user_id: userId,
            },
            config: {
                enabled: groupId !== undefined && userGroupId === undefined && userHasAccount === false,
            },
        },
        requestType: RequestTypes.POST,
        invalidate: false,
    }) as UseQueryResult<any>;

    useEffect(() => {
        const refetchOperatedData = Boolean(
            createUserGroupQuery &&
                !createUserGroupQuery.isLoading &&
                createUserGroupQuery.data &&
                Object.keys(createUserGroupQuery.data).length > 0,
        );

        const validInputs = Boolean(companyName && typeof groupId === 'number' && userGroupId === undefined);

        if (refetchOperatedData && validInputs) {
            screenerUserGroupRes?.refetch();
            createUserGroupQuery?.remove();
            setUserHasAccount(undefined);
            setGroupId(undefined);
            setUserGroupId(undefined);
            onSuccess();
        }
    }, [createUserGroupQuery, companyName, groupId, userGroupId, onSuccess, screenerUserGroupRes]);

    return updateUserGroupQuery || createUserGroupQuery;
};
