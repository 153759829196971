import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ETFPageContainer } from 'components/layout/ETFPageContainer';
import { homePageTheme } from 'components/themes/theme';

export function Home() {
    return (
        <ThemeProvider theme={homePageTheme}>
            <ETFPageContainer containerStyles={{ maxWidth: 'unset', backgroundColor: 'unset' }}>
                <Box data-testid='home-page-container'></Box>
            </ETFPageContainer>
        </ThemeProvider>
    );
}
