import { HorizontalPagePaddingString } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

function PermanentDrawerLeftToolbar({ height }: { height: number }) {
    if (height <= 0) {
        return null;
    }
    return <Toolbar sx={{ height: `${height}px` }} />;
}

export function PermanentDrawerLeft({
    children,
    drawerContent,
    drawerWidth,
    topGap,
}: {
    children: React.ReactNode;
    drawerContent: React.ReactNode;
    drawerWidth: number;
    topGap: number;
}) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        paddingLeft: HorizontalPagePaddingString,
                    },
                }}
                variant='permanent'
                anchor='left'>
                <PermanentDrawerLeftToolbar height={topGap} />
                <Divider />
                {drawerContent}
            </Drawer>
            <Box component='main' sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
                {children}
            </Box>
        </Box>
    );
}
