import { AccordionsSidebar } from '@cfra-nextgen-frontend/shared/src/components/AccordionsSidebar';
import { ControlledAccordionsProps } from '@cfra-nextgen-frontend/shared/src/components/ControlledAccordions';
import { topNavigationHeight } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import { TopNavigation } from 'features/topNavigation';
import { Outlet } from 'react-router-dom';
import { AppRoutePaths } from 'utils/enums';

export default function AuthenticatedRoutesWrapper() {
    const sidebarOptions: ControlledAccordionsProps['config'] = [
        {
            name: 'Manage Accounts',
            onClickPath: AppRoutePaths.ManageAccounts,
        },
        {
            name: 'Manage Users',
            onClickPath: AppRoutePaths.ManageUsers,
        },
        {
            name: 'Manage Packages',
            onClickPath: AppRoutePaths.ManagePackages,
        },
        // {
        //     name: 'Home',
        //     items: [
        //         { name: 'Overview' },
        //         { name: PageNames.Users, onClickPath: AppRoutePaths.Users },
        //         { name: 'Groups' },
        //         { name: 'Accounts' },
        //         { name: 'Packages' },
        //         { name: PageNames.Entitlements, onClickPath: AppRoutePaths.Entitlements },
        //     ],
        //     defaultSelected: true,
        // },
        // {
        //     name: 'Packages',
        //     items: [{ name: 'Create New Package' }, { name: 'Edit Package' }, { name: 'Delete Package' }],
        // },
        // {
        //     name: PageNames.Entitlements,
        //     onClickPath: AppRoutePaths.Entitlements,
        //     items: [{ name: 'Create New Entitlement' }, { name: 'Edit Entitlement' }, { name: 'Delete Entitlement' }],
        // },
    ];

    return (
        <>
            <TopNavigation topNavigationContentHeight={topNavigationHeight} />
            <AccordionsSidebar topGap={topNavigationHeight} accordionsConfig={{ config: sidebarOptions }}>
                <Outlet />
            </AccordionsSidebar>
        </>
    );
}
