import { BASE_API_URL } from '@cfra-nextgen-frontend/shared/src/config';
import { getFullRequestQuery } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { rest } from 'msw';
import accountProfileGroupsCardScreenerData from './json/accountProfile/groupsCard/screenerData.json';
import accountProfilePackagesCardScreenerData from './json/accountProfile/packagesCard/screenerData.json';
import accountProfileProfileCardFiltersData from './json/accountProfile/profileCard/filtersData.json';
import accountProfileProfileCardScreenerData from './json/accountProfile/profileCard/screenerData.json';
import accountProfileUsersCardBulkCreateUserModalFiltersData from './json/accountProfile/usersCard/bulkCreateUserModal/filtersData.json';
import accountProfileUsersCardFiltersData from './json/accountProfile/usersCard/filtersData.json';
import accountProfileUsersCardScreenerData from './json/accountProfile/usersCard/screenerData.json';
import manageAccountsCreateAccountModalFiltersData from './json/manageAccounts/createAccountModal/filtersData.json';
import manageAccountsScreenerData from './json/manageAccounts/screenerData.json';
import manageUsersCreateUserModalFiltersData from './json/manageUsers/createUserModal/filtersData.json';
import manageUsersScreenerData from './json/manageUsers/screenerData.json';
import userProfileGroupsCardGroupScreenerData from './json/userProfile/groupsCard/groupScreenerData.json';
import userProfileGroupsCardUserGroupScreenerData from './json/userProfile/groupsCard/userGroupScreenerData.json';
import userProfilePackagesCardScreenerData from './json/userProfile/packagesCard/screenerData.json';
import userProfileProfileCardFiltersData from './json/userProfile/profileCard/filtersData.json';
import userProfileProfileCardScreenerData from './json/userProfile/profileCard/screenerData.json';

const getFullPlatformManagementInternalRequestQuery = (requestQuery: string) =>
    getFullRequestQuery({ requestQuery: requestQuery, apiName: ApiNames.PlatformManagementInternal });

export const handlersForTests = [
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener/account')}`,
        (req, res, ctx) => {
            const view = req.url.searchParams.get('view');
            switch (view) {
                case 'default':
                    // mock for manage accounts - screener data
                    return res(ctx.json(manageAccountsScreenerData));
                case 'account_profile':
                    // mock for account profile - profile card
                    return res(ctx.json(accountProfileProfileCardScreenerData));
            }
        },
    ),
    rest.post(`${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener/group')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');
        switch (view) {
            case 'account_profile':
                // mock for account profile - groups card
                return res(ctx.json(accountProfileGroupsCardScreenerData));
            case 'user_profile':
                // mock for user profile - groups card
                return res(ctx.json(userProfileGroupsCardGroupScreenerData));
        }
    }),
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener/user_group')}`,
        (req, res, ctx) => {
            const view = req.url.searchParams.get('view');
            switch (view) {
                case 'default':
                    // mock for user profile - groups card - user group data
                    return res(ctx.json(userProfileGroupsCardUserGroupScreenerData));
            }
        },
    ),
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener/group_package')}`,
        (req, res, ctx) => {
            const view = req.url.searchParams.get('view');
            switch (view) {
                case 'account_profile':
                    // mock for account profile - packages card
                    return res(ctx.json(accountProfilePackagesCardScreenerData));
                case 'user_profile':
                    // mock for user profile - packages card
                    return res(ctx.json(userProfilePackagesCardScreenerData));
            }
        },
    ),
    rest.post(`${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener/user')}`, (req, res, ctx) => {
        const view = req.url.searchParams.get('view');

        switch (view) {
            case 'default':
                // mock for manage users - screener data
                return res(ctx.json(manageUsersScreenerData));
            case 'account_profile':
                // mock for account profile - users card
                return res(ctx.json(accountProfileUsersCardScreenerData));
            case 'user_profile':
                // mock for user profile - profile card
                return res(ctx.json(userProfileProfileCardScreenerData));
        }
    }),
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener-filters/account')}`,
        (req, res, ctx) => {
            const type = req.url.searchParams.get('type');
            switch (type) {
                case 'edit':
                    // mock for account profile - profile card edit filters data
                    return res(ctx.json(accountProfileProfileCardFiltersData));
                case 'create':
                    // mock for manage accounts - create account modal filters data
                    return res(ctx.json(manageAccountsCreateAccountModalFiltersData));
            }
        },
    ),
    rest.post(
        `${BASE_API_URL}${getFullPlatformManagementInternalRequestQuery('internal/screener-filters/user')}`,
        (req, res, ctx) => {
            const type = req.url.searchParams.get('type');

            switch (type) {
                case 'create':
                    // mock for manage users - create user modal filters
                    return res(ctx.json(manageUsersCreateUserModalFiltersData));
                case 'edit':
                    // mock for user profile - profile card edit filters data
                    return res(ctx.json(userProfileProfileCardFiltersData));
                case 'bulk_edit':
                    // mock for account profile - users card
                    return res(ctx.json(accountProfileUsersCardFiltersData));
                case 'bulk_create':
                    // mock for account profile - users card - bulk create user modal
                    return res(ctx.json(accountProfileUsersCardBulkCreateUserModalFiltersData));
            }
        },
    ),
];

export const handlersForMSW = handlersForTests;
