import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { MultipleModalsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext/MultipleModalsContext';
import { useContext } from 'react';
import { okButtonText } from './utils';

export function InformationPopup({ modalId, analyticsCardName }: { modalId: string; analyticsCardName: string }) {
    const { getModalState, multipleModalsStateDispatcher } = useContext(MultipleModalsContext);
    const modalState = getModalState(modalId);
    return (
        <ConfirmationModal
            openModal={Boolean(modalState?.isOpen)}
            title={modalState?.title}
            modalContent={modalState?.content}
            confirmCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${okButtonText}`,
                    cardName: analyticsCardName,
                });

                multipleModalsStateDispatcher({
                    type: 'resetModalsState',
                    newState: {
                        modals: {
                            [modalId]: undefined,
                        },
                    },
                });
            }}
            modalBoxStyles={{
                maxHeight: '90%',
                padding: '32px',
            }}
            descriptionStyle={{
                overflow: 'auto',
            }}
            confirmButtonText={okButtonText}
            buttonContainerStyles={{ paddingTop: '16px' }}
            childrenContainerStyles={{
                flexDirection: 'column',
            }}
        />
    );
}
